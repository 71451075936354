import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer } from '../common/components/toast/ToastComponents';
import { authCheckState } from '../store/actions/auth/auth-actions';
import NavigationSwitch from '../components/Navigation/NavigationSwitch';
import LoginNavigation from '../components/Login/LoginNavigation';
import Layout from '../hoc/Layout';
import { LoginContainer } from '../components/Login/LoginStyles';
import { withRouter } from 'react-router-dom';
import { Spinner } from '../common/components/ui/CommonStyledComponent';

const spinnerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%'
}

function App({ history }) {
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!auth.user) {
			dispatch(authCheckState());
		}
	}, [auth.authenticated]);

	useEffect(()=> {
		history.replace(auth.authRedirectPath);
	}, [auth.authRedirectPath]);

	let view = (
		<div style={spinnerStyle}>
			<Spinner size={'4x'}/>
		</div>);
	
	if (auth.authenticated && !auth.loading && auth.user) {
		view = (
			<Layout>
				<NavigationSwitch />
			</Layout>
		);
	}
	else if (!auth.authenticated && !auth.loading) {
		view = (
			<LoginContainer>
				<LoginNavigation />
			</LoginContainer>
		);
	}
	

	return (
		<ToastProvider components={{ ToastContainer: ToastContainer }}
			placement="bottom-center">
			{view}
		</ToastProvider>
	);
}

export default withRouter(App);
