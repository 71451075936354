import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../../../store/utilities';

const initialState = {
  loading: false,
  error: null,
};

const deleteUserLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const deleteUserFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_USER_START:
      return deleteUserLoading(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAILED:
      return deleteUserFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
