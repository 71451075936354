import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Routes from '../../../components/routes/index';
import { loadReport, cleanReport, editReport } from '../store/reducers/reports-reducer';
import { loadPost, deletePostById, cleanPost } from '../store/reducers/post-reducer';
import { formatDate } from '../../../common/utils/date-utils';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { imagePath } from '../../../common/utils/utils';
import DeletePostPopup from './DeletePostPopup';
import { Label, FlexColumn, FlexRow, FlexRowCentered, Spinner, PageTitle } from '../../../common/components/ui/CommonStyledComponent';
import { MButtonBlue, MButtonRed, BackButtonTBlue } from '../../../common/components/ui/Buttons';
import FullPageLayout from '../../../components/PageLayout/FullPageLayout';
import StyledSelect from '../../../common/components/ui/Select';

const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'In review', value: 'in_review' },
  { label: 'Closed', value: 'closed' },
];

const ReportedPost = ({ history, match }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.reports);
  const { post, loading: postLoading, error: postError } = useSelector((state) => state.post);

  const [status, setStatus] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const getMatchOption = (value) => {
    return statusOptions.find((opt) => opt.value === value);
  };

  const onBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(Routes.Reports.reports);
    }
  };

  let reportId = '';
  if (match.params.id) {
    reportId = match.params.id;
  } else {
    onBack();
  }

  let postId = '';
  if (match.params.postId) {
    postId = match.params.postId;
  } else {
    onBack();
  }

  useEffect(() => {
    dispatch(loadPost(postId));
    dispatch(loadReport(reportId)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setStatus(data.payload.report.status);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanPost());
      dispatch(cleanReport());
    };
  }, []);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
    if (postError) {
      addToast(postError, { appearance: 'error', autoDismiss: true });
    }
  }, [error, postError]);

  const deletePostHandler = () => {
    dispatch(deletePostById(postId)).then((data) => {
      if (!data.error) {
        addToast('Post has been successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        setShowDeletePopup(false);
      }
    });
  };

  const editReportHandler = () => {
    const updateInfo = {
      status,
    };
    dispatch(editReport(reportId, updateInfo)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Report Edited', { appearance: 'success', autoDismiss: true });
      }
    });
  };

  return (
    <FullPageLayout.FullPageContainer>
      <FullPageLayout.ListContainer style={{ maxWidth: '80%' }}>
        <FullPageLayout.BackBar>
          <BackButtonTBlue onClick={onBack}>Back</BackButtonTBlue>
        </FullPageLayout.BackBar>
        <FlexRow>
          {postLoading ? (
            <FlexRowCentered style={{ padding: '25px 0' }}>
              <Spinner size='2x' />
            </FlexRowCentered>
          ) : (
            <FlexColumn style={{ width: '60%' }}>
              <PageTitle style={{ fontSize: '25px', alignSelf: 'flex-start' }}>{'Reported Post'}</PageTitle>
              {post._id ? (
                <FlexColumn style={{ margin: '0 20px', width: '100%' }}>
                  <FlexColumn style={{ width: '100%', margin: '10px 20px 10px 0' }}>
                    <Label style={{ padding: '5px 0' }}>Title:</Label>
                    <p>{post.text}</p>
                  </FlexColumn>
                  <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                    <Label style={{ padding: '5px 0' }}>Description:</Label>
                    <p>{post.description}</p>
                  </FlexColumn>
                  <FlexRow style={{justifyContent: 'space-between'}}>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Type:</Label>
                      <p>{post.type}</p>
                    </FlexColumn>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Condition:</Label>
                      <p>{post.condition}</p>
                    </FlexColumn>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Show To:</Label>
                      <p>{post.showTo}</p>
                    </FlexColumn>
                  </FlexRow>
                  <FlexRow style={{justifyContent: 'space-between'}}>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Given:</Label>
                      <p>{post.given ? 'true' : 'false'}</p>
                    </FlexColumn>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Posted By:</Label>
                      <p>{post.postedBy ? post.postedBy.fullName : null}</p>
                    </FlexColumn>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Create Date:</Label>
                      <p>{formatDate(post.createDate)}</p>
                    </FlexColumn>
                  </FlexRow>
                  <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                    <Label style={{ padding: '5px 0' }}>Images:</Label>
                    <FlexRow>
                      {post.images
                        ? post.images.map((image, index) => {
                          return (
                            <RoundProfileImage
                              key={image + index}
                              radius={5}
                              width={400}
                              height={400}
                              imgUrl={imagePath(image)}
                              withButton={false}
                              withDeleteButton={false}
                            />
                          );
                        })
                        : null}
                    </FlexRow>
                  </FlexColumn>
                </FlexColumn>
              ) : (
                <Label style={{ padding: '5px 0' }}>Post Deleted</Label>
              )}
              <MButtonRed
                onClick={() => setShowDeletePopup(true)}
                style={{
                  width: '150px',
                  margin: '10px 0px',
                }}
                disabled={postLoading || !post._id}>
                Delete
              </MButtonRed>
              {showDeletePopup ? (
                <DeletePostPopup
                  post={post}
                  loading={loading}
                  positiveButtonAction={deletePostHandler}
                  negativeButtonAction={() => {
                    setShowDeletePopup(false);
                  }}
                />
              ) : null}
            </FlexColumn>
          )}
          {loading ? (
            <FlexRowCentered style={{ padding: '25px 0' }}>
              <Spinner size='2x' />
            </FlexRowCentered>
          ) : (
            <FlexColumn style={{ width: '60%' }}>
              <PageTitle style={{ fontSize: '25px', alignSelf: 'flex-start' }}>{'Report Status'}</PageTitle>
              <FlexColumn style={{ width: '50%' }}>
                <StyledSelect value={getMatchOption(status)} options={statusOptions} onChange={(choice) => setStatus(choice.value)} />
              </FlexColumn>
              <MButtonBlue
                onClick={() => editReportHandler()}
                style={{
                  width: '150px',
                  margin: '10px 0px',
                }}
                disabled={loading}>
                Save
              </MButtonBlue>
            </FlexColumn>
          )}
        </FlexRow>
      </FullPageLayout.ListContainer>
    </FullPageLayout.FullPageContainer>
  );
};

export default ReportedPost;
