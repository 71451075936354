import * as actionTypes from '../../actions/management/actionTypes';
import changePasswordReducer from './profile/password-change-reducer';
import profileReducer from './profile/profile-reducer';

const initialState = {
	changePassword: changePasswordReducer(undefined, {}),
	profile: profileReducer(undefined, {}),
};

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case actionTypes.PROFILE_LOADING: 
		case actionTypes.PROFILE_LOADING_SUCCESS: 
		case actionTypes.PROFILE_LOADING_FAILED: 
		case actionTypes.PROFILE_UPDATE_SUCCESS:
		case actionTypes.PROFILE_UPDATE_FAILED:
		case actionTypes.PROFILE_AVATAR_UPDATE_SUCCESS:
		case actionTypes.PROFILE_AVATAR_UPDATE_FAILED:
		case actionTypes.PROFILE_CLEAN: return {...state, profile: profileReducer(state.profile, action)};

		case actionTypes.PROFILE_PASSWORD_CHANGING:
		case actionTypes.PROFILE_PASSWORD_CHANGING_SUCCESS:
		case actionTypes.PROFILE_PASSWORD_CHANGING_FAILED: return {...state, changePassword: changePasswordReducer(state.changePassword, action)};

		default:
			return state;
	}
};

export default reducer;