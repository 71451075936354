import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const Separator = styled.div`
    padding: 0;
    margin: ${props => props.margin ? props.margin : '20px 0'};
    width: 100%;
    height: 1px;
    background: ${props => props.color ? props.color : 'var(--neutral-30p)'};
`;

export const PageTitle = styled.div`
	align-self: center;
	font-family: InterUI-SemiBold;
	font-size: 46px;
	color: var(--neutral-80p);
    padding: 30px 0px;
`;

export const VerticalSeparator = styled.div`
    padding: 0;
    margin: ${props => props.margin ? props.margin : '20px 20px'};
    width: 1px;
    background: ${props => props.color ? props.color : 'var(--neutral-30p)'};

    @media(max-width:1265px) {
        display: none;
	}
`;

export const ClickableImage = styled.img`
	cursor: pointer;
	&:active{
		box-shadow: 0px 0px 5px 0px gray;
		background: 'black'
	}
`;

export const BoldTitle = styled.div`
	color: var(--neutral-80p);
	font-size: 18px;
	font-family: 'InterUI-SemiBold';
`;

export const TopBubble = styled.div`
    max-width: 310px;
	z-index: 101;
    right: 0;
    top: 30px;
	opacity: 1;
	visibility: visible;
	position: absolute;
	padding: 10px;
	width: auto;
	background: #FFFFFF;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.30);
	border-radius: 5px;
	&::before {
		position: absolute;
		z-index: 1;
		content: "";
		top: -10px;
		right: 10%;
		border-style: solid;
		border-width: 0px 12px 12px 12px;
		border-color: transparent transparent #FFFFFF transparent;
	}
`;

export const RightBubble = styled.div`
	z-index: 101;
    right: 10px;
    top: 0px;
	opacity: 1;
	visibility: visible;
	position: absolute;
	width: auto;
	background: #FFFFFF;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.30);
	border-radius: 5px;
	&::before {
		position: absolute;
		z-index: -1;
		content: "";
		top: 50%;
		right: -10px;
		border-style: solid;
		border-width: 12px 0px 12px 12px;
		border-color: transparent transparent transparent #FFFFFF;
	}
`;

export const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 16px 0px;
    z-index: 1;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.50);
    width: ${props => props.collapse ? '0px' : '410px'};
    overflow-y: hidden;
    flex: 1;
`;

export const DatePickerContainer = styled.div`
	height: 42px;
	margin: 8px auto;
	/* position: relative; */
    width: 100%;
    border: 1px solid var(--neutral-30p);
`;

export const WarningDiv = styled.div`
    color:red;
    align-self: flex-start;
    margin: 0px 0px 20px 0px;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 520px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
`;

export const StyledInput = styled.input`
    color: ${props => props.color || 'var(--neutral-90p)'};
    font-size: 16px;
    padding: 0px 20px;
    height: 44px;
    margin: 8px auto;
    width: calc(100% - 42px);
    border-style: groove;
    border:1px solid var(--neutral-30p);

    &.invalid{
        border-color: var(--secondary-red);
    }
    
    &:disabled {
        border:1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }

    &.readOnly:disabled {
        color: var(--neutral-80p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder { /* Edge */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &::placeholder {
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }
`;

export const Label = styled.label`
    display: block;
	font-size: 16px;
    align-self: flex-start;
    font-weight: bold;
`;

export const StyledTextArea = styled.textarea`
    font-family: InterUI-Regular;
    resize: none;
    height: ${props => props.height || '76px'};
    width: ${props => props.width || '100%'};
    border: 1px solid var(--neutral-30p);
    padding: 0.625rem;
    overflow: auto;
    font-size: 16px;
    border:1px solid var(--neutral-30p);
    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }
    &:disabled {
        border:1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }
    &.readOnly:disabled {
        color: var(--neutral-80p);
    }
`;

export const ExternalLink = styled.a`
    color: ${props => props.color ? props.color : 'var(--blue-shade-light)'};
    text-decoration: ${props => props.underline ? 'underline' : 'none'};

    &:hover {
        cursor: pointer;
    }
`;

export const StyledText = styled.div`
    color: ${props => props.color ? props.color : 'var(--blue-shade-light)'};
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
    font-size: 16px;
    font-family: InterUI-Regular;
    padding-left: 16px;

    &:hover {
        cursor: pointer;
    }
    &.disabled {
        cursor: default;
        text-decoration: none;
        color: var(--neutral-60p);
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 17px;
    & > .input-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: max-content;
    }
    & > .title {
        font-family: InterUI-SemiBold;
        color: var(--neutral-90p);
    }
    & > .value {
        font-family: InterUI-Regular;
        color: var(--neutral-60p);
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

export const InputContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
`;


export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexRowAligned = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRowSpaced = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: 'center';
`;

export const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FlexRowWrapped = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    background: #F2DEDE;
    border: 1px solid #EBCCCC;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    & > .message {
        font-family: InterUI-Regular;
        font-size: 16px;
        color: #AB352D;
        letter-spacing: 0;
        text-align: left;
        line-height: 25px;
    }
`;

export const StyledLink = styled(Link)`
    font-family: InterUI-Medium;
    font-size: 16px;
    color: #0061A8;
    text-decoration: none;
`

export const EllipsisDiv = styled.div`
    max-width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
`;

export const Spinner = ({ style, ...restProps }) => {
    return (
        <FontAwesomeIcon style={{ color: 'var(--blue-shade-dark)', ...style }} icon={faCircleNotch} spin size="2x" {...restProps} />
    );
}