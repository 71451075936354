import { createSlice } from '@reduxjs/toolkit';

import { getId } from '../../../../common/utils/utils';
import groupsApi from '../api/groups-api';

const initialState = {
  groups: [],
  metadata: { total: 0, page: 1 },
  group: {},
  loading: false,
  error: null,
};

const _loadStart = (state, action) => {
  state.loading = true;
  state.error = null;
};

const _loadFailed = (state, action) => {
  const { error } = action.payload;
  state.loading = false;
  state.error = error;
};

const _loadGroupsSuccess = (state, action) => {
  const { groups, metadata } = action.payload;
  state.loading = false;
  state.groups = groups;
  state.metadata = metadata;
};

const _cleanGroups = (state) => {
  state = initialState;
};

const _loadGroupSuccess = (state, action) => {
  const { group } = action.payload;
  state.loading = false;
  state.group = group;
};

const _editGroupSuccess = (state, action) => {
  state.loading = false;
};

const _cleanGroup = (state) => {
  state = initialState;
};

const _deleteGroupSuccess = (state, action) => {
  state.loading = false;
};

const _createGroupSuccess = (state, action) => {
  state.loading = false;
};

const groups = createSlice({
  name: 'groups',
  initialState: initialState,
  reducers: {
    loadStart: _loadStart,
    loadFailed: _loadFailed,
    loadGroupsSuccess: _loadGroupsSuccess,
    cleanGroups: _cleanGroups,
    loadGroupSuccess: _loadGroupSuccess,
    editGroupSuccess: _editGroupSuccess,
    cleanGroup: _cleanGroup,
    deleteGroupSuccess: _deleteGroupSuccess,
    createGroupSuccess: _createGroupSuccess,
  },
});

const { actions, reducer } = groups;
export const {
  loadStart,
  loadFailed,
  loadGroupsSuccess,
  updateGroupsSuccess,
  cleanGroups,
  loadGroupSuccess,
  editGroupSuccess,
  cleanGroup,
  deleteGroupSuccess,
  createGroupSuccess,
} = actions;
export default reducer;

export const groupsSelector = (state) => state.groups;

export const loadGroups = (page = 1, limit = 6, searchValue = '') => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { status, data, error } = await groupsApi.fetchGroups(page, limit, getId(), searchValue);
    if (status === 200) {
      return dispatch(loadGroupsSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const loadGroup = (groupId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { status, data, error } = await groupsApi.getGroup(getId(), groupId);
    if (status === 200) {
      return dispatch(loadGroupSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const editGroup = (groupId, updateInfo) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await groupsApi.updateGroup(groupId, { ...updateInfo }, getId());
    if (status === 200 && data) {
      return dispatch(editGroupSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const deleteGroupById = (groupId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await groupsApi.deleteGroupById(groupId, getId());
    if (status === 200 && data) {
      return dispatch(deleteGroupSuccess());
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const createGroup = (info) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await groupsApi.createGroup(info, getId());
    if (status === 200 && data) {
      return dispatch(createGroupSuccess());
    } else {
      return dispatch(loadFailed(error));
    }
  };
};
