import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../../../store/utilities';

const initialState = {
  users: [],
  metadata: { total: 0, page: 1 },
  loading: false,
  error: null,
};

const usersLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const usersLoadSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    users: action.payload.users,
    metadata: action.payload.metadata,
  });
};

const usersLoadFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const cleanUsers = (state, action) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USERS_START:
      return usersLoading(state, action);
    case actionTypes.LOAD_USERS_SUCCESS:
      return usersLoadSuccess(state, action);
    case actionTypes.LOAD_USERS_FAILED:
      return usersLoadFailed(state, action);
    case actionTypes.CLEAN_USERS:
      return cleanUsers(state, action);
    default:
      return state;
  }
};

export default reducer;
