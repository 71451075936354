import {
  actionLoading,
  actionSuccess,
  actionFail,
  actionClean,
} from '../../../../store/actions/common/common-actions';
import * as actionTypes from './actionTypes';
import { getId } from '../../../../common/utils/utils';
import usersApi from '../api/users-api';

export const loadUsers = (page = 1, limit = 6, searchValue = '') => {
  return async (dispatch) => {
    dispatch(actionLoading(actionTypes.LOAD_USERS_START));
    const { status, data, error } = await usersApi.fethUsers(page, limit, getId(), searchValue);
    if (status === 200) {
      return dispatch(actionSuccess(actionTypes.LOAD_USERS_SUCCESS, data));
    } else {
      return dispatch(actionFail(actionTypes.LOAD_USERS_FAILED, error));
    }
  };
};

export const cleanUsers = () => {
  return actionClean(actionTypes.CLEAN_USERS);
};

export const loadUser = (userId) => {
  return async (dispatch) => {
    dispatch(actionLoading(actionTypes.LOAD_USER_START));
    const { status, data, error } = await usersApi.getUser(getId(), userId);
    if (status === 200) {
      return dispatch(actionSuccess(actionTypes.LOAD_USER_SUCCESS, data));
    } else {
      return dispatch(actionFail(actionTypes.LOAD_USER_FAILED, error));
    }
  };
};

export const editUser = (userId, updateInfo) => {
  return async (dispatch) => {
    dispatch(actionLoading(actionTypes.EDIT_USER_START));
    const { data, status, error } = await usersApi.updateUser(userId, { ...updateInfo }, getId());
    if (status === 200 && data) {
      return dispatch(actionSuccess(actionTypes.EDIT_USER_SUCCESS, data));
    } else {
      return dispatch(actionFail(actionTypes.EDIT_USER_FAILED, error));
    }
  };
};

export const deleteUserById = (userId) => {
  return async (dispatch) => {
    dispatch(actionLoading(actionTypes.DELETE_USER_START));
    const { data, status, error } = await usersApi.deleteUserById(userId, getId());
    if (status === 200 && data) {
      return dispatch(actionSuccess(actionTypes.DELETE_USER_SUCCESS));
    } else {
      return dispatch(actionFail(actionTypes.DELETE_USER_FAILED, error));
    }
  };
};

export const cleanUser = () => {
  return actionClean(actionTypes.CLEAN_USER);
};
