import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../../../store/utilities';

const initialState = {
  user: {},
  loading: false,
  error: null,
};

const userLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const userLoadSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.payload.profile,
  });
};

const userLoadFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const cleanUser = (state, action) => {
  return updateObject(state, initialState);
};

const editUserLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const editUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const editUserFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USER_START:
      return userLoading(state, action);
    case actionTypes.LOAD_USER_SUCCESS:
      return userLoadSuccess(state, action);
    case actionTypes.LOAD_USER_FAILED:
      return userLoadFailed(state, action);
    case actionTypes.EDIT_USER_START:
      return editUserLoading(state, action);
    case actionTypes.EDIT_USER_SUCCESS:
      return editUserSuccess(state, action);
    case actionTypes.EDIT_USER_FAILED:
      return editUserFailed(state, action);
    case actionTypes.CLEAN_USER:
      return cleanUser(state, action);
    default:
      return state;
  }
};

export default reducer;
