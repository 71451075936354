import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../../store/utilities';

const initialState = {
	loading: false,
	error: null
};

const sendMessageLoading = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
}

const sendMessageSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
	});
}

const sendMessageFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error
	});
}

const cleanMessage = (state, action) => {
	return updateObject(state, initialState);
}

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case actionTypes.SEND_MESSAGE_START: return sendMessageLoading(state, action);
		case actionTypes.SEND_MESSAGE_SUCCESS: return sendMessageSuccess(state, action);
		case actionTypes.SEND_MESSAGE_FAILED: return sendMessageFailed(state, action);
		case actionTypes.CLEAN_SEND_MESSAGE: return cleanMessage(state, action);
		default:
			return state;
	}
};

export default reducer;