import { actionLoading, actionSuccess, actionFail, actionClean } from '../../../../store/actions/common/common-actions';
import * as actionTypes from './actionTypes';
import { getId } from '../../../../common/utils/utils';
import messagesApi from '../api/messages-api';

export const sendMessage = (message) => {
    return async (dispatch) => {
        dispatch(actionLoading(actionTypes.SEND_MESSAGE_START));
        const { data, status, error } = await messagesApi.sendMessage(message, getId());
        console.log(data);
        if (status === 200) {
            return dispatch(actionSuccess(actionTypes.SEND_MESSAGE_SUCCESS));
        } else {
            return dispatch(actionFail(actionTypes.SEND_MESSAGE_FAILED, error));
        }
    };
};

export const cleanMessage = () => {
    return actionClean(actionTypes.CLEAN_SEND_MESSAGE);
};
