import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorDiv = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: .25rem;
    height: 50px;
    color: #721c24;
    border: 1px solid #f5c6cb;
    background-color: #f8d7da;
    align-items: center;
    width: 100%;
    margin: 0px 0px 20px 0px;
    text-align: start;
`;

const ErrorBox = (props) => {
    return (
        <ErrorDiv>
            <FontAwesomeIcon
                style={{margin:"0px 20px"}} 
                color="#721c24" 
                size="lg" 
                icon={faExclamationTriangle}></FontAwesomeIcon>
            {props.children}
        </ErrorDiv>
    )
}

export default ErrorBox;