import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema, updateResponseSchema } from './schemas/common-schemas';
import { validate } from './schemas/validator';
import { profileSchema } from './schemas/account-schemas';

const fetchUserProfile = async (requestId) => {
	try {
		const headers = {
			requestId: requestId
		}
		const { status, data, error } = await axiosInstance.get(ServerRoutes.userProfile, { headers: headers });
		const { result, e, s } = await validate(profileSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const updateUserProfile = async (profile, requestId) => {
	try {
		const headers = {
			requestId: requestId
		}
		const { status, data, error } = await axiosInstance.put(ServerRoutes.userProfile, profile, { headers: headers });
		const { result, e, s } = await validate(updateResponseSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 400: 
				message = 'User data error';
				break;
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const updateUserAvatar = async (avatar, requestId) => {
	try {
		const headers = {
			requestId: requestId
		}
		const { status, data, error } = await axiosInstance.put(ServerRoutes.userAvatar, avatar, { headers: headers });
		const { result, e, s } = await validate(updateResponseSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 400: 
				message = 'User data error';
				break;
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const resetPassword = async (passwords, requestId) => {
	try {
		const headers = {
			requestId: requestId
		}
		const { status, data, error } = await axiosInstance.put(ServerRoutes.resetPassword, passwords, { headers: headers });
		const { result, e, s } = await validate(updateResponseSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 400: 
				message = 'User data error';
				break;
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

export default {
	fetchUserProfile: fetchUserProfile,
	updateUserProfile: updateUserProfile,
	updateUserAvatar :updateUserAvatar,
	resetPassword: resetPassword
}