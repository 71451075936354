import { string, object } from 'yup';

export const profileSchema = object().shape({
	firstName: string().required(),
	lastName: string().required(),
	avatar: string().nullable(),
	jobTitle: string().nullable(),
	secondaryEmail: string(),
	phone: string(),
	secondaryPhone: string()
});
