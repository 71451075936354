import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const StyledSelect = ({styles,...props}) => {
	return (
		<Select {...props} styles={styles ? styles : {
			menu: (style) => ({
				...style,
				backgroundColor: 'hsl(0,0%,100%)',
				borderRadius: '0',
				boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
				marginBottom: '8px',
				marginTop: '8px',
				zIndex: '20',
				boxSizing: 'border-box',
				minWidth: 'max-content',
			}),
			option: (style) => {
				return ({
					...style,
					padding: '0.5rem 1.5rem',
					color: 'black',
					backgroundColor: 'white',
					zIndex: '100',
					':hover': {
						backgroundColor: 'var(--neutral-20p)'
					},
					':active': {
						backgroundColor: 'var(--neutral-20p)'
					},
					':focus': {
						backgroundColor: 'var(--neutral-20p)'
					},
					':focus-within': {
						backgroundColor: 'var(--neutral-20p)'
					},
					':visited': {
						backgroundColor: 'var(--neutral-20p)'
					}
				})
			},
			container: (style) => ({
				...style,
				width: props.width ? props.width : '100%',
				margin: '8px 0px'
			}),
			control: (style) => ({
				...style,
				height: props.height ? props.height : '44px',
				boxShadow: '0 0 0 0 var(--neutral-30p)',
				borderColor: 'var(--neutral-30p)',
				':hover': {
					borderColor: 'var(--neutral-30p)',
					boxShadow: '0 0 0 0 var(--neutral-30p)'
				}
			}),
			indicatorSeparator: (style) => ({
				...style,
				display: 'none'
			}),
			indicatorsContainer: (style) => ({
				...style,
				display: 'flex',
				fill: props.isDisabled ? 'var(--neutral-50p)' : 'var(--orange-shade-dark)',
				'svg': {
					fill: props.isDisabled ? 'var(--neutral-50p)' : 'var(--orange-shade-dark)'
				}
			})
		}}
			theme={theme => {
				return ({
					...theme,
					borderRadius: 0,
					height: '44px',
					colors: {
						...theme.colors,
						primary: 'var(--neutral-30p)',
						primary25: 'transparent'
					},
				})
			}}
		/>
	)
}

StyledSelect.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
		label: PropTypes.string
	})),
	value: PropTypes.oneOfType([PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
		label: PropTypes.string
	}),PropTypes.arrayOf(PropTypes.shape())]),
	height: PropTypes.string,
	width: PropTypes.string,
	isDisabled: PropTypes.bool,
	isMulti: PropTypes.bool,
	isSearchable: PropTypes.bool,
	onChange: PropTypes.func,
	placeholder: PropTypes.string
}

export default StyledSelect;