import axios from 'axios';
import axiosCancel from 'axios-cancel';
import env from './config';

let headers = {
    client: 'web',
    clientVersion: env.APP_VERSION,
};
if (process.env.REACT_APP_STAGE === 'int') {
    headers = { 
        client: 'web',
        clientVersion: env.APP_VERSION,
    };
}

axios.defaults.withCredentials = false

const instance = axios.create({
    baseURL: env.apiGateway.API_SERVER_URL,
    headers: headers,
});
instance.defaults.withCredentials = false

axiosCancel(instance, {
    debug: true
});

export default instance;

export const updateToken = () => {
    instance.defaults.headers['Authorization'] = localStorage.getItem('markall_token');
}
