import React from 'react';
import styled from 'styled-components';
import { contextMenu, Menu, Item } from 'react-contexify';
import { useTranslation } from 'react-i18next';

import moreActions from '../../assets/icons/more-actions-gray.svg';

import '../../styles/context-menu-css-override.css'

const ActionsCellContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	& > img {
		cursor: pointer;
	}
	& > img:active {
		filter: invert(1)
	}
`;

const DefaultContextMenuActionCell = (props) => {
	const { t } = useTranslation();

	const handleEvent = (e) => {
		contextMenu.show({
			id: props.id ? props.id : 'action_menu',
			event: e,
			props: props.row
		});
	}
	return (
		<img src={moreActions} onClick={handleEvent} alt={t('more-actions.label')} />
	);
}

export const ActionCell = (props) => {
	return (
		<ActionsCellContainer>
			<DefaultContextMenuActionCell {...props} />
		</ActionsCellContainer>
	);
}

export const ActionsContextMenu = (props) => {
	let list = props.funcs.map((item, index) => {
		return <Item key={index}
			style={{ fontFamily: "InterUI-Regular", cursor: "pointer" }}
			className={"context-menu-item"}
			onClick={item.func}>
			{item.title}
		</Item>
	})
	return (
		<Menu id="action_menu"  className={'context-menu'}>
			{list}
		</Menu>
	);
}