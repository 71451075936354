import React from 'react';
import { ActionCell } from '../../../components/tables/CommonTableComponents';
import { formatDate } from '../../../common/utils/date-utils';
import Routes from '../../../components/routes/index';
import { NavLink } from 'react-router-dom';

const columns = [
  {
    id: 'reporter',
    Header: 'Reporter',
    accessor: 'userId.fullName',
    disableSortBy: true,
    width: '14%',
  },
  {
    id: 'reason',
    Header: 'Reason',
    accessor: 'reason',
    disableSortBy: true,
    width: '14%',
  },
  {
    id: 'status',
    Header: 'status',
    accessor: 'status',
    disableSortBy: true,
    width: '14%',
  },
  {
    id: 'type',
    Header: 'type',
    accessor: 'type',
    disableSortBy: true,
    width: '14%',
  },
  {
    id: 'externalId',
    Header: 'ID',
    accessor: 'externalId',
    Cell: (props) => {
      if (props.row.original.type === 'group') {
        return (
          <NavLink
            style={{ textDecoration: 'none', color: 'black' }}
            to={Routes.Reports.viewGroup.replace(':id', props.row.original._id).replace(':groupId', props.value)}>
            {props.value}
          </NavLink>
        );
      }
      if (props.row.original.type === 'post') {
        return (
          <NavLink
            style={{ textDecoration: 'none', color: 'black' }}
            to={Routes.Reports.viewPost.replace(':id', props.row.original._id).replace(':postId', props.value)}>
            {props.value}
          </NavLink>
        );
      }
      return null;
    },
    disableSortBy: true,
    width: '14%',
  },
  {
    id: 'lastUpdate',
    Header: 'Last Update',
    accessor: 'updateDate',
    Cell: (props) => {
      if (props.value) {
        return formatDate(props.value);
      } else {
        return null;
      }
    },
    disableSortBy: true,
    width: '10%',
  },
  {
    id: 'createDate',
    Header: 'Create Date',
    accessor: 'createDate',
    Cell: (props) => {
      if (props.value) {
        return formatDate(props.value);
      } else {
        return null;
      }
    },
    disableSortBy: true,
    width: '10%',
  },
  {
    id: 'Actions',
    Header: 'Actions',
    accessor: (props) => props,
    Cell: (props) => <ActionCell {...props} />,
    disableSortBy: true,
    width: '10%',
  },
];

export default columns;
