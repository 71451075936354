import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import logo from '../../assets/icons/login-logo.jpg';

import { MButtonBlue } from '../../common/components/ui/Buttons';
import EmailInput from '../../common/components/ui/inputs/EmailInput';
import { Wrapper, WarningDiv, Label, StyledInput, FlexRow } from '../../common/components/ui/CommonStyledComponent';
import { authUserLogin } from '../../store/actions/auth/auth-actions';
import { FormDiv } from './LoginStyles';
import { Checkbox } from '../../common/components/ui/Checkbox';
import ErrorBox from '../../common/components/ui/ErrorBox';

const Login = () => {
    const { email, error } = useSelector((state) => state.auth);

    const [emailValue, setEmailValue] = useState(email || '');
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [remember, setRemember] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const emailValueChangeHandler = (value) => {
        setEmailValue(value);
    };

    const passwordValueChangeHandler = (event) => {
        setPasswordValue(event.target.value);
        checkPasswordValid(event.target.value);
    };

    const checkEmailValid = (validity) => {
        if (emailValue !== '') setEmailValid(validity);
    };

    const checkPasswordValid = (value) => {
        if (value.length >= 1 && passwordValid !== true) {
            setPasswordValid(true);
        } else if (value.length === 0 && passwordValid === true) {
            setPasswordValid(false);
        }
    };

    const signInHandler = (event) => {
        event.preventDefault();
        if (emailValid && emailValue !== '' && passwordValid && passwordValue !== '') {
            dispatch(authUserLogin(emailValue, passwordValue, remember));
        }
    };

    return (
        <Wrapper style={{ maxWidth: '460px' }}>
            <FormDiv onSubmit={signInHandler}>
                <a href='/'>
                    <img src={logo} height='50' alt='/' />
                </a>
                <h2 style={{ padding: '20px 0' }}>{t('log-in.label')}</h2>
                {error ? <ErrorBox>{error}</ErrorBox> : null}
                <Label>{t('email-address.label')}</Label>
                <EmailInput onChange={emailValueChangeHandler} value={emailValue} setValidity={checkEmailValid}></EmailInput>
                {!emailValid ? <WarningDiv style={{ margin: '0px' }}>{t('valid-email-required.label')}</WarningDiv> : null}
                <Label style={{ marginTop: '20px' }}>{t('password.label')}</Label>
                <StyledInput style={{ marginBottom: '28px' }} name='password' onChange={passwordValueChangeHandler} type='password' value={passwordValue}></StyledInput>
                {!passwordValid ? <WarningDiv>{t('password-is-required.label')}</WarningDiv> : null}
                <MButtonBlue type='submit'>{t('log-in.label')}</MButtonBlue>
                <FlexRow style={{ alignSelf: 'center', marginTop: '22px' }}>
                    <Checkbox onChange={(e) => setRemember(e.target.checked)} name='remember' type='checkbox' text={t('keep-me-logged-in.label')} />
                </FlexRow>
            </FormDiv>
        </Wrapper>
    );
};

export default Login;
