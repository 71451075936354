import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-style.css';

import { useTranslation } from 'react-i18next';
import Modal from '../../../common/components/ui/PopupWindowLayout';
import { StyledInput, Label, FlexColumn, DatePickerContainer, FlexRow } from '../../../common/components/ui/CommonStyledComponent';
import StyledSelect from '../../../common/components/ui/Select';
import PhoneInput from '../../../common/components/ui/PhoneField';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { getImagePresignedUrl } from '../../../store/actions/files/files-actions';
import ImageUploadPopup from '../../../common/components/ui/ImageUploadPopup';
import { imagePath } from '../../../common/utils/utils';

const genders = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
];

const EditProfilePopup = ({ user, loading, positiveButtonAction, negativeButtonAction }) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const [fullName, setFullName] = useState(user.fullName || '');
    const [birthDate, setBirthDate] = useState(user.birthDate ? user.birthDate : new Date());
    const [phone, setPhone] = useState(user.phone || '');
    const [countryCode, setCountryCode] = useState(user.countryCode || '');
    const [gender, setGender] = useState(user.gender || '');
    const [avatar, setAvatar] = useState(user.avatar);
    const [oldImage, setOldImage] = useState(null);
    const [showImageUploadPopup, setShowImageUploadPopup] = useState(false);

    const getMatchOption = (value) => {
        return genders.find((opt) => opt.value === value);
    };

    const onSave = () => {
        if (!fullName || fullName === '') {
            addToast('Name is empty!', { appearance: 'error', autoDismiss: true });
            return;
        }
        const updatedUser = {
            fullName,
            birthDate,
            gender,
            phone,
            countryCode,
            avatar,
            email: user.email,
        };
        positiveButtonAction(user._id, updatedUser, oldImage);
    };

    const onImageUploadDone = (fileName) => {
        setOldImage(user.avatar);
        setAvatar(fileName);
    };

    const onImageDelete = () => {
        if (avatar) {
            setAvatar(null);
        }
    };

    const onClose = () => {
        negativeButtonAction();
    };

    return (
        <Modal
            negativeButtonAction={onClose}
            positiveButtonAction={onSave}
            positiveButtonTitle={'Save'}
            title={'Edit Profile'}
            titleStyle={{ paddingBottom: '30px' }}
            popupType={'submit'}
            popupStyle={{ top: '55%' }}
            loading={loading}
            body={
                <>
                    <FlexColumn style={{ width: '100%' }}>
                        <FlexColumn style={{ margin: '0 10px', width: '100%' }}>
                            <RoundProfileImage
                                radius={5}
                                width={190}
                                height={140}
                                fontSize={'40px'}
                                imgUrl={imagePath(avatar)}
                                withButton={true}
                                withDeleteButton={false}
                                deleteClickHandler={onImageDelete}
                                changeClickedHandler={() => setShowImageUploadPopup(true)}
                            />
                            <FlexRow>
                                <FlexColumn style={{ width: '50%' }}>
                                    <FlexColumn style={{ width: '100%', margin: '20px 0' }}>
                                        <Label style={{ padding: '5px 0' }}>{t('full-name.label')}</Label>
                                        <StyledInput
                                            value={fullName}
                                            onChange={(event) => {
                                                if (!event.target.value || event.target.value === '') {
                                                    addToast('Name is empty!', { appearance: 'error', autoDismiss: true });
                                                }
                                                setFullName(event.target.value);
                                            }}
                                        />
                                    </FlexColumn>

                                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                                        <Label style={{ padding: '5px 0' }}>Birth Date</Label>
                                        <DatePickerContainer style={{ border: 'none', margin: '8px 0px' }}>
                                            <DatePicker placeholderText='Date' dateFormat='dd/MM/yyyy' onChange={(date) => setBirthDate(date.toString())} selected={new Date(birthDate)} />
                                        </DatePickerContainer>
                                    </FlexColumn>
                                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                                        <Label style={{ padding: '5px 0' }}>Gender</Label>
                                        <StyledSelect value={getMatchOption(gender)} options={genders} onChange={(choice) => setGender(choice.value)} />
                                    </FlexColumn>
                                    <FlexColumn style={{ width: '100%' }}>
                                        <Label style={{ padding: '5px 0px' }}>Phone</Label>
                                        <PhoneInput
                                            value={countryCode + phone}
                                            onChange={(value, country) => {
                                                setCountryCode(country.countryCode);
                                                setPhone(value);
                                            }}
                                        />
                                    </FlexColumn>
                                </FlexColumn>
                                <FlexColumn style={{ width: '40%', margin: '20px' }}>
                                    <Label style={{ padding: '5px 0' }}>{t('addresses.label')}</Label>
                                    {user.addresses &&
                                        user.addresses.map((address) => {
                                            return <StyledInput key={address._id} value={address.address} disabled={true} />;
                                        })}
                                </FlexColumn>
                            </FlexRow>
                        </FlexColumn>
                        {showImageUploadPopup ? (
                            <ImageUploadPopup
                                closeAction={() => setShowImageUploadPopup(false)}
                                onUploadDone={onImageUploadDone}
                                getPresignedUrlAction={(fileName, requestId) => dispatch(getImagePresignedUrl(fileName, requestId, null, user._id))}
                                deleteImage={(fileName, requestId) => (fileName = null)}
                            />
                        ) : null}
                    </FlexColumn>
                </>
            }></Modal>
    );
};

export default EditProfilePopup;
