import { string, object } from 'yup';

export const userInfoSchema = object().shape({
	firstName: string().required(),
	lastName: string(),
	company: string(),
	phone: string(),
	role: string(),
	_id: string().required(),
	email: string().email().required()
});

export const wsTicketSchema = object().shape({
	ticket: string().required()
});