import React, { useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Pagination from './Pagination';

const TableHeader = styled.th`
    width: ${(props) => (props.width ? props.width : '150px')};
    position: relative;
`;

const Span = styled.span`
    position: absolute;
    margin: 0 5px;
`;

const SpinnerContainer = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100px;
    left: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 20px;
`;

const Spinner = () => {
    return <FontAwesomeIcon style={{ color: '#232323' }} icon={faCircleNotch} spin size='2x' />;
};

const Table = ({ columns, data, limit, pages, loading, currentPage, onPageChange, totalPageButtons, onSort, manualSort, multiSort }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage, pageSize: limit },
            disableMultiSort: !multiSort,
            manualSortBy: manualSort,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        onSort(
            sortBy.map((filter) => {
                return { sortBy: filter.id, order: filter.desc ? -1 : 1 };
            })[0]
        );
    }, [onSort, sortBy]);

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <TableHeader width={column.width} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <Span>{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</Span>
                                </TableHeader>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : null}
            {pages > 1 ? (
                <Pagination onPageChange={onPageChange} gotoPage={gotoPage} pageIndex={pageIndex} pageSize={pageSize} setPageSize={setPageSize} pageCount={pages} totalPageButtons={totalPageButtons} />
            ) : null}
        </>
    );
};

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    limit: PropTypes.number,
    totalPageButtons: PropTypes.number,
    onSort: PropTypes.func,
    multiSort: PropTypes.bool,
    manualSort: PropTypes.bool,
};

Table.defaultProps = {
    data: [],
    columns: [],
    limit: 5,
    totalPageButtons: 3,
    onSort: () => {},
    multiSort: false,
    manualSort: true,
};

export default Table;
