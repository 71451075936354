import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { PageSection } from '../../../components/PageLayout/PageSection';
import GeneralTable from '../../../components/tables/GeneralTable';
import columns from './ReportsTableColumns';
import StyledSelect from '../../../common/components/ui/Select';
import { FlexColumn, FlexRow, Label } from '../../../common/components/ui/CommonStyledComponent';
import { loadReports, cleanReports, loadReport, editReport, cleanReport, deleteReportById } from '../store/reducers/reports-reducer';
import { ActionsContextMenu } from '../../../components/tables/CommonTableComponents';
import Routes from '../../../components/routes/index';
import EditReportPopup from './EditReportPopup';
import DeleteReportPopup from './DeleteReportPopup';

const limit = 5;

const typeOptions = [
  { label: 'All', value: '' },
  { label: 'Post', value: 'post' },
  { label: 'Group', value: 'group' },
];

const statusOptions = [
  { label: 'All', value: '' },
  { label: 'New', value: 'new' },
  { label: 'In review', value: 'in_review' },
  { label: 'Closed', value: 'closed' },
];

const reasonOptions = [
  { label: 'All', value: '' },
  { label: 'Sexual', value: 'sexual' },
  { label: 'Violent', value: 'violent' },
  { label: 'Hateful', value: 'hateful' },
  { label: 'Harmful', value: 'harmful' },
  { label: 'Spam', value: 'spam' },
];

const Reports = ({ history }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { reports, report, loading, error, metadata } = useSelector((state) => state.reports);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(loadReports(1, limit, searchValue, type, status, reason));
    }, 350);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, type, status, reason]);

  useEffect(() => {
    return () => {
      dispatch(cleanReports());
    };
  }, []);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error]);

  const getMatchOption = (type, value) => {
    switch (type) {
      case 'type':
        return typeOptions.find((opt) => opt.value === value);
      case 'status':
        return statusOptions.find((opt) => opt.value === value);
      case 'reason':
        return reasonOptions.find((opt) => opt.value === value);
      default:
        return null;
    }
  };

  const changePage = (page) => {
    dispatch(loadReports(page, limit, searchValue, type, status, reason));
  };

  const onEditHandler = (data) => {
    if (data.props.original.type === 'group') {
      history.push(Routes.Reports.viewGroup.replace(':id', data.props.original._id).replace(':groupId', data.props.original.externalId));
    }

    if (data.props.original.type === 'post') {
      history.push(Routes.Reports.viewPost.replace(':id', data.props.original._id).replace(':postId', data.props.original.externalId));
    }
  };

  const onDeleteHandler = (data) => {
    dispatch(loadReport(data.props.original._id)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setShowDeletePopup(true);
      }
    });
  };

  const deleteReportHandler = (reportId) => {
    dispatch(deleteReportById(reportId)).then((data) => {
      if (!data.error) {
        addToast('Report has been successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        dispatch(loadReports(1, limit, searchValue, type, status, reason));
        setShowDeletePopup(false);
      }
    });
  };

  const editReportHandler = (reportId, updateInfo) => {
    dispatch(editReport(reportId, updateInfo)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Report Edited', { appearance: 'success', autoDismiss: true });
        dispatch(loadReports(1, limit, searchValue, type, status, reason));
      }
      setShowPopup(false);
      dispatch(cleanReport());
    });
  };

  const handlers = [
    { title: 'Open Report', func: onEditHandler },
    { title: 'Delete Report Row', func: onDeleteHandler },
  ];

  return (
    <PageSection style={{ width: '100%', height: 'calc(100% - 65px)', boxSizing: 'border-box' }}>
      <FlexColumn style={{ padding: '20px', position: 'relative' }}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexColumn style={{ width: '20%' }}>
            <Label style={{ padding: '5px 0' }}>Type</Label>
            <StyledSelect value={getMatchOption('type', type)} options={typeOptions} onChange={(choice) => setType(choice.value)} />
          </FlexColumn>
          <FlexColumn style={{ width: '20%' }}>
            <Label style={{ padding: '5px 0' }}>Status</Label>
            <StyledSelect value={getMatchOption('status', status)} options={statusOptions} onChange={(choice) => setStatus(choice.value)} />
          </FlexColumn>
          <FlexColumn style={{ width: '20%' }}>
            <Label style={{ padding: '5px 0' }}>Reason</Label>
            <StyledSelect value={getMatchOption('reason', reason)} options={reasonOptions} onChange={(choice) => setReason(choice.value)} />
          </FlexColumn>
        </FlexRow>
        <GeneralTable
          loading={loading}
          data={reports}
          columns={columns}
          limit={limit}
          pages={Math.ceil(metadata.total / limit)}
          page={metadata.page}
          onPageChange={changePage}
        />
        <ActionsContextMenu funcs={handlers} />
        {showPopup ? (
          <EditReportPopup
            report={report}
            loading={loading}
            positiveButtonAction={editReportHandler}
            negativeButtonAction={() => {
              setShowPopup(false);
              dispatch(cleanReport());
            }}
          />
        ) : null}
        {showDeletePopup ? (
          <DeleteReportPopup
            report={report}
            loading={loading}
            positiveButtonAction={deleteReportHandler}
            negativeButtonAction={() => {
              setShowDeletePopup(false);
            }}
          />
        ) : null}
      </FlexColumn>
    </PageSection>
  );
};

export default Reports;
