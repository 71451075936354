import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { PageSection } from '../../../components/PageLayout/PageSection';
import GeneralTable from '../../../components/tables/GeneralTable';
import columns from './GroupsTableColumns';
import SearchInput from '../../../common/components/ui/inputs/SearchInput';
import Routes from '../../../components/routes/index';
import { FlexColumn, FlexRow } from '../../../common/components/ui/CommonStyledComponent';
import { loadGroups, cleanGroups, loadGroup, editGroup, cleanGroup, deleteGroupById } from '../store/reducers/groups-reducer';
import { ActionsContextMenu } from '../../../components/tables/CommonTableComponents';
import EditGroupPopup from './EditGroupPopup';
import DeleteGroupPopup from './DeleteGroupPopup';
import { MButtonBlue } from '../../../common/components/ui/Buttons';

const limit = 100;

const Groups = ({ history }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);

  const { groups, group, loading, error, metadata } = useSelector((state) => state.groups);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(loadGroups(1, limit, searchValue));
    }, 350);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    return () => {
      dispatch(cleanGroups());
    };
  }, []);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error]);

  const changePage = (page) => {
    dispatch(loadGroups(page, limit, searchValue));
  };

  const onEditHandler = (data) => {
    dispatch(loadGroup(data.props.original._id)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setShowPopup(true);
      }
    });
  };

  const onDeleteHandler = (data) => {
    dispatch(loadGroup(data.props.original._id)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setshowDeletePopup(true);
      }
    });
  };

  const onCreateHandler = () => {
    history.push(Routes.Groups.createGroup);
  };

  const deleteGroupHandler = (groupId) => {
    dispatch(deleteGroupById(groupId)).then((data) => {
      if (!data.error) {
        addToast('Group has been successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        dispatch(loadGroups(1, limit, searchValue));
        setshowDeletePopup(false);
      }
    });
  };

  const editGroupHandler = (groupId, updateInfo, oldImage) => {
    dispatch(editGroup(groupId, updateInfo)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Group Edited', { appearance: 'success', autoDismiss: true });
        dispatch(loadGroups(1, limit, searchValue));
      }
      setShowPopup(false);
      dispatch(cleanGroup());
    });
  };

  const handlers = [
    { title: 'Edit', func: onEditHandler },
    { title: 'Delete', func: onDeleteHandler },
  ];

  return (
    <PageSection style={{ width: '100%', height: 'calc(100% - 65px)', boxSizing: 'border-box' }}>
      <FlexColumn style={{ padding: '20px', position: 'relative' }}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexColumn>
            <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} inputStyle={{ borderRadius: '0px', height: '40px' }} />
            {metadata && metadata.total ? <div>Total users: {metadata.total}</div> : null}
          </FlexColumn>
          <MButtonBlue
            style={{
              margin: '8px 0',
              width: '15%',
            }}
            onClick={onCreateHandler}>
            New Group
          </MButtonBlue>
        </FlexRow>
        <GeneralTable
          loading={loading}
          data={groups}
          columns={columns}
          limit={limit}
          pages={Math.ceil(metadata.total / limit)}
          page={metadata.page}
          onPageChange={changePage}
        />
        <ActionsContextMenu funcs={handlers} />
        {showPopup ? (
          <EditGroupPopup
            group={group}
            loading={loading}
            positiveButtonAction={editGroupHandler}
            negativeButtonAction={() => {
              setShowPopup(false);
              dispatch(cleanGroup());
            }}
          />
        ) : null}
        {showDeletePopup ? (
          <DeleteGroupPopup
            group={group}
            loading={loading}
            positiveButtonAction={deleteGroupHandler}
            negativeButtonAction={() => {
              setshowDeletePopup(false);
            }}
          />
        ) : null}
      </FlexColumn>
    </PageSection>
  );
};

export default Groups;
