import filesApi from '../../api/files-api';

export const getImagePresignedUrl = (fileName, requestId, groupId, userId) => {
  return async () => {
    let body = { fileName: fileName };
    if (groupId) {
      body['groupId'] = groupId;
    }
    if (userId) {
      body['userId'] = userId;
    }
    return await filesApi.getImagePresignedUrl(body, requestId);
  };
};

export const deleteImage = (fileName, groupId, requestId) => {
  return async () => {
    return await filesApi.deleteImage({ fileName: fileName, groupId: groupId }, requestId);
  };
};
