// messages
export const LOAD_MESSAGES_START = "LOAD_MESSAGES_START";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const LOAD_MESSAGES_FAILED = "LOAD_MESSAGES_FAILED";
export const CLEAN_MESSAGES = "CLEAN_MESSAGES";

//send message
export const SEND_MESSAGE_START = "SEND_MESSAGE_START";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCES";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
export const CLEAN_SEND_MESSAGE = "CLEAN_SEND_MESSAGE";
