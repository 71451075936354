import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faMinus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import cancel from '../../../assets/icons/cancel.svg';
import copy from '../../../assets/icons/copy.svg';
import iconArrowDown from '../../../assets/icons/arrow-down.svg';


const GeneralButton = styled.button`
	cursor: pointer;
	font-family: InterUI-SemiBold;
	font-size: 16px;
	text-align: center;
	line-height: 16px;
	width: max-content;
	border-radius: 2px;
	background: transparent;
	&:focus {
		outline: none;
	}
	&:hover {
		border-color: transparent;
	}
	&:disabled {
		&:hover {
			background-color: var(--neutral-20p);
			color: var(--neutral-50p);
		}
		border: none;
		color: var(--neutral-50p);
		background-color: var(--neutral-20p);
	}
`;

export const LButton = styled(GeneralButton)`
	background: var(--orange-shade-dark);
	border: 2px solid var(--orange-shade-dark);
	color: #FFFFFF;
	padding: 18px 34px;
	height: 52px;
	&:hover {
		background: var(--orange-shade-lighter);
	}
	&:active {
		background: var(--orange-shade-dark);
	}
`;

export const MButtonBlue = styled(GeneralButton)`
	box-sizing: border-box;
	background: var(--primary-blue);
	border: 2px solid var(--primary-blue);
	color: #FFFFFF;
	height: 44px;
	width: 100%;
	&:hover {
		background: var(--blue-shade-light);
	};
	&:active {
		background: var(--blue-shade-dark);
	}
`;

export const MButtonRed = styled(MButtonBlue)`
	background: var(--primary-red);
	border: 2px solid var(--primary-red);
	&:hover {
		background: var(--secondary-light-red);
	};
	&:active {
		background: var(--secondary-red-dark);
	}
`;

export const MButton = styled(GeneralButton)`
	box-sizing: border-box;
	background: var(--orange-shade-dark);
	border: 2px solid var(--orange-shade-dark);
	color: #FFFFFF;
	height: 52px;
	width: 100%;
	margin: 8px 0 0 0;

	&:hover {
		background: var(--orange-shade-lighter);
	};
	&:active {
		background: var(--orange-shade-dark);
	}
`;

export const MButtonTBlack = styled(GeneralButton)`
	border: 2px solid var(--neutral-50p);
	color: var(--neutral-80p);
	padding: 14px 23px;
	height: 44px;

	&:hover {
		background: var(--neutral-80p);
		color: #FFFFFF;
	}
	&:active {
		color: var(--neutral-80p);
		background: var(--neutral-50p);
	}
`;

export const MButtonTBlue = styled(GeneralButton)`
	border: 2px solid var(--neutral-30p);
	background: transparent;
	color: ${props => props.color || 'var(--blue-shade-medium)'};
	padding: 14px 23px;
	height: 44px;
	display: flex;
	justify-content: space-between;

	&:hover {
		background: var(--blue-shade-medium);
		color: #FFFFFF;
	}
	&:active {
		color: var(--blue-shade-medium);
		background: var(--blue-shade-light);
	}
`;

export const SButtonTBlack = styled(GeneralButton)`
	border: 2px solid var(--neutral-80p);
	font-family: InterUI-Medium;
	font-size: 14px;
	color: var(--neutral-80p);
	line-height: 14px;
	padding: 10px 20px;
	/* height: 34px; */

	&:hover {
		background: var(--neutral-80p);
		color: #FFFFFF;
	}
	&:active {
		color: var(--neutral-80p);
		background: var(--neutral-50p);
	}
`;

export const SButtonTOrange = styled(GeneralButton)`
	border: 1px solid var(--orange-shade-dark);
	font-family: InterUI-Medium;
	font-size: 14px;
	color: var(--orange-shade-dark);
	line-height: 14px;
	padding: 10px 20px;
	/* height: 34px; */

	&:hover {
		background: var(--orange-shade-dark);
		color: #FFFFFF;
	}
	&:active {
		color: var(--orange-shade-dark);
		background: var(--orange-shade-lighter);
	}
`;

const Button = styled(GeneralButton)`
	background: ${props => props.bg || '#FFFFFF'};
	border: 1px solid ${props => props.borderColor || 'var(--neutral-40p)'};
	border-radius: 16px;
	color: var(--primary-drak-blue);
	padding: 8px 16px;
	min-width: 87px;
	height: 32px;

	&:hover {
		background: var(--blue-shade-medium);
		color: #FFFFFF;
		border: none;
	}
	&:active {
		color: var(--blue-shade-medium);
		background: var(--blue-shade-light);
	}
`;

const CircleButton = styled(GeneralButton)`
    display: flex;
    justify-content: center;
	align-items: center;
	background: ${props => props.bg || '#FFFFFF'};
	border: 1px solid ${props => props.borderColor || 'var(--neutral-30p)'};
	color: ${props => props.color || 'var(--secondary-green)'};
	/* border-radius: 50%; */
	height: ${props => props.height || '32px'};
	width: ${props => props.width || '32px'};

	&:hover {
		background: ${props => props.color || 'var(--secondary-green)'};
		color: #FFFFFF;
		border-color: transparent;
	}
	&:disabled {
		&:hover {
			border: 2px solid ${props => props.borderColor || 'var(--neutral-40p)'};
		}
		color: var(--neutral-50p);
		background-color: var(--neutral-20p);
	}
`;

export const CopyButton = (props) =>{
	return (
		<CircleButton style={{borderRadius: '50%'}} bg={'none'} color={'var(--neutral-20p)'} {...props}>
			<img src={copy} alt="link" style={{height: '18px', marginTop: '2px'}}/>
		</CircleButton>
	)
}

export const PlusButton = (props) => {
	return (
		<CircleButton {...props}>
			<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
		</CircleButton>
	);
}

export const MinusButton = (props) => {
	return (
		<CircleButton color={'var(--secondary-red)'} width={'36px'} {...props}>
			<FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
		</CircleButton>
	);
}


const MarkButton = styled.button`
    background:transparent;
    border: none;
    width: max-content;
    font-size: ${props => props.fontSize || '16px'};
    height: ${props => props.fontSize || '16px'};
    color: var(--neutral-50p);
	display: flex;

    &:hover {
        color: var(--neutral-60p);
		cursor: pointer;
    }
	&:focus {
		outline: none;
	}

`;

export const QuastionMarkButton = (props) => {
	return (
		<MarkButton onClick={props.onClick}>
			<FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
		</MarkButton>
	);
}

export const XMarkButton = (props) => {
	return (
		<MarkButton onClick={props.onClick} fontSize={props.fontSize}>
			{/* <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> */}
			<img src={cancel} alt="X"/>
		</MarkButton>
	);
}

export const ArrowDownButton = (props) => {
	return (
		<MarkButton onClick={props.onClick}>
			<img src={iconArrowDown} style={ props.upside ? {transform :'scaleY(-1)'} : null} alt="arrowDown"/>
		</MarkButton>
	);
}

export const BackButtonTBlue = (props) => {
	return (
		<Button {...props}>
			<FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> {props.children}
		</Button>
	);
}

export const IconButton = styled(GeneralButton)`
	background: ${props => props.disabled ? '#FFFFFF' : 'transparent'};
	border: 0;

	&:hover {
		background: var(--neutral-20p);
	}

	&:disabled {
		border: 1px solid var(--neutral-30p);
		background: #FFFFFF;
		&:hover {
			background: #FFFFFF;
			cursor: default;

		}
	}
`;



