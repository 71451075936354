import React from 'react';

import { FlexRow } from '../../common/components/ui/CommonStyledComponent';

const NavControlList = () => {

	return (
		<FlexRow style={{position: 'relative', marginRight: '30px', cursor: 'pointer'}}>
		</FlexRow>

	);
}

export default NavControlList;