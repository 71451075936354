import axiosInstance from '../../../../axios';
import GroupsRoutes from './routes/index';
import { validate } from '../../../../store/api/schemas/validator';
import { errorSchema, updateResponseSchema } from '../../../../store/api/schemas/common-schemas';

const fetchGroups = async (page, limit, requestId, searchGroups) => {
  try {
    const headers = {
      'f-page': page,
      'f-limit': limit,
      requestId: requestId,
    };

    let url = GroupsRoutes.groups;
    let url2 = GroupsRoutes.groups + '/totalgroups';
    if (searchGroups !== '') {
      url += '?search=' + searchGroups;
      url2 += '?search=' + searchGroups;
    }

    const { status, data, error } = await axiosInstance.get(url, { headers: headers });
    if (data) {
      const { status: status2, data: data2, error: error2 } = await axiosInstance.get(url2, { headers: headers });
      let payload = { groups: data.groups, metadata: { total: data2.totalGroups, page: page } };
      return { data: payload, status: status2 ? status2 : status, error: error2 ? error2 : error };
    }
  } catch (error) {
    console.error(JSON.stringify(error));
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Groups not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const getGroup = async (requestId, groupId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = GroupsRoutes.groups;
    url = url + '/' + groupId;
    const { data, status, error } = await axiosInstance.get(url, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Group not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const updateGroup = async (groupId, updateInfo, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = GroupsRoutes.groups;
    url = url + '/' + groupId;

    const { data, status, error } = await axiosInstance.put(url, updateInfo, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);

    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 403:
        message = 'Not permitted';
        break;
      case 404:
        message = 'Group not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const createGroup = async (info, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = GroupsRoutes.groups;
    const { data, status, error } = await axiosInstance.put(url, info, {
      headers: headers,
    });
    return { data, status, error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Group not created';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const deleteGroupById = async (groupId, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = GroupsRoutes.groups;
    url = url + '/' + groupId;
    const { data, status, error } = await axiosInstance.delete(url, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Group not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const groupsApi = {
  fetchGroups: fetchGroups,
  getGroup: getGroup,
  updateGroup: updateGroup,
  createGroup: createGroup,
  deleteGroupById: deleteGroupById,
};

export default groupsApi;
