import { formatLastUpdate } from '../../../common/utils/date-utils';

const columns = [
    {
        id: 'subject',
        Header: 'Subject',
        accessor: 'subject',
        sortType: 'basic',
        width: '30%',
    },
    {
        id: 'text',
        Header: 'Text',
        accessor: 'message',
        sortType: 'basic',
        width: '30%',
    },
    {
        id: 'date',
        Header: 'Date',
        accessor: 'create_date',
        Cell: (props) => {
            if (props.value) {
                return formatLastUpdate(props.value);
            } else {
                return null;
            }
        },
        sortType: 'basic',
        width: '30%',
    },
];

export default columns;
