import {number, object, string, array} from 'yup';

export const errorSchema = object().shape({
	response: object().required().default({status: 300}).shape({
		status: number().required().integer().default(300)
	})
});

export const updateResponseSchema = object().shape({
	ok: number().default(0)
});

export const metadataSchema = object().shape({
	page: number().required().default(1),
	total: number().required().default(0)
}).required().default({
	page: 1,
	total: 0
});


export const addressSchema = object().shape({
    formatted_address: string(),
    place_id: string()
})
export const locationSchema = object().shape({
    coordinates: array().transform((value)=>{return value && value.length === 2 ? value : [0,0]}).default([0,0]),
    _id: string().required(),
    type: string()
})
export const geofenceSchema = object().shape({
    address: object().shape({
        formatted_address: string(),
        place_id: string().required()
    }).default(null),
    _id: string().required(),
    placeName: string(),
    description: string(),
    radius: number().default(100),
    latitude: number().default(0),
    longitude: number().default(0),
    geoType: string(),
    geoColor: string(),
    geoPolygon: object().shape({
        coordinates: array().of(array().of(array().transform((value)=>{return value && value.length === 2 ? value : [0,0]}).default([0,0]))),
        _id: string(),
        type: string()
    })
}).default(null);

export const sharedLinkSchema = object().shape({
    link: string().required()
})