import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import logo from '../../assets/icons/login-logo.jpg';

import Nav from './NavigationStyledComponents';
import { ProfileMenu } from './ProfileMenu';
import OutsideClick from '../../hoc/OutsideClick';
import NavRoutes from './NavRoutes';
import NavControlsList from './NavControlList';
import Routes from '../routes/index';

const Navigation = ({ history, ...props }) => {
    const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);
    const auth = useSelector((state) => state.auth);

    const openDropDown = () => {
        setProfileDropDownOpen(!profileDropDownOpen);
    };

    return (
        <TransitionGroup>
            <CSSTransition classNames={'nav-move-down'} exit={false} appear={true} enter={false} timeout={{ appear: 300, exit: 0, enter: 0 }}>
                <>
                    <Nav.Nav>
                        <NavLink to={Routes.Users.users}>
                            <Nav.NavLogo src={logo} />
                        </NavLink>
                        <Nav.NavVerticalSeparator />
                        <NavRoutes />
                        <Nav.NavControlsContainer>
                            <NavControlsList />
                            <Nav.NavProfileButton onClick={openDropDown} user={auth.user}></Nav.NavProfileButton>
                        </Nav.NavControlsContainer>
                    </Nav.Nav>
                    {profileDropDownOpen ? (
                        <OutsideClick callback={openDropDown}>
                            <ProfileMenu callback={openDropDown} user={auth.user} />
                        </OutsideClick>
                    ) : null}
                </>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default withRouter(Navigation);
