import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useEmailValidator } from '../../../../hooks/validators/useRegExValidator';
import { StyledInput } from '../CommonStyledComponent';


const EmailInput = ( props ) =>{
    const [value, setValue] = useState(props.value);
    const valid = useEmailValidator(value) && value.length > 0;
    const [dirty, setDirty] = useState(false);

    useState(()=> {
        setDirty(value !== '');
    }, [])

    useEffect(() => {
        setValue(props.value)
    },[props.value])

    useEffect(() => {
        props.onChange(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    
    const valueChangeHandler = (event) => {
        if (!dirty) {
            setDirty(true);
        }
        setValue(event.target.value);
    }

    return (
        <StyledInput 
            onBlur={()=> props.setValidity(valid)}
            onChange={valueChangeHandler}
            value={value}
            className={!valid && dirty ? "invalid" : null} />    
    );
}

EmailInput.propTypes = {
    value: PropTypes.string,
    setValidity: PropTypes.func,
    onChange: PropTypes.func
}

EmailInput.defaultProps = {
    value: '',
    setValidity: () =>{},
    onChange: () =>{},
}

export default EmailInput;
