import { string, object, array } from 'yup';
import { metadataSchema } from '../../../../../store/api/schemas/common-schemas';

export const messageSchema = object().shape({
	_id: string().required(),
	subject: string(),
	text: string(),
	date: string(),
});

export const messagesArraySchema = object().shape({
	messages: array().of(messageSchema).default([]),
	metadata: metadataSchema
});
