import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema } from './schemas/common-schemas';

const getImagePresignedUrl = async (body, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    const { data, status, error } = await axiosInstance.post(ServerRoutes.filestoragePresignedUrl, body, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 400:
        message = 'Error while uploading';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const deleteImage = async (body, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    const { data, status, error } = await axiosInstance.post(ServerRoutes.filestorageDeleteImage, body, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Error while deleting file';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

export default {
  getImagePresignedUrl: getImagePresignedUrl,
  deleteImage: deleteImage,
};
