import { createSlice } from '@reduxjs/toolkit';

import { getId } from '../../../../common/utils/utils';
import reportsApi from '../api/reports-api';

const initialState = {
  post: {},
  loading: false,
  error: null,
};

const _loadStart = (state, action) => {
  state.loading = true;
  state.error = null;
};

const _loadFailed = (state, action) => {
  const { error } = action.payload;
  state.loading = false;
  state.error = error;
};

const _loadPostSuccess = (state, action) => {
  const { post } = action.payload;
  state.loading = false;
  state.post = post;
};

const _cleanPost = (state) => {
  state = initialState;
};

const _deletePostSuccess = (state, action) => {
  state.loading = false;
};

const post = createSlice({
  name: 'post',
  initialState: initialState,
  reducers: {
    loadStart: _loadStart,
    loadFailed: _loadFailed,
    loadPostSuccess: _loadPostSuccess,
    cleanPost: _cleanPost,
    deletePostSuccess: _deletePostSuccess,
  },
});

const { actions, reducer } = post;
export const { loadStart, loadFailed, loadPostSuccess, cleanPost, deletePostSuccess } = actions;
export default reducer;

export const postSelector = (state) => state.post;

export const loadPost = (postId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { status, data, error } = await reportsApi.getPost(getId(), postId);
    if (status === 200) {
      return dispatch(loadPostSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const deletePostById = (postId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await reportsApi.deletePostById(postId, getId());
    if (status === 200 && data) {
      return dispatch(deletePostSuccess());
    } else {
      return dispatch(loadFailed(error));
    }
  };
};
