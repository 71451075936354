import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../common/components/ui/PopupWindowLayout';
import { FlexColumn } from '../../../common/components/ui/CommonStyledComponent';

const DeleteProfilePopup = ({ user, loading, positiveButtonAction, negativeButtonAction }) => {
  const { t } = useTranslation();

  return (
    <Modal
      negativeButtonAction={negativeButtonAction}
      positiveButtonAction={() => positiveButtonAction(user._id)}
      positiveButtonTitle={'Delete'}
      title={'Delete Account'}
      titleStyle={{ paddingBottom: '30px' }}
      popupType={'delete'}
      loading={loading}
      body={
        <>
          <FlexColumn style={{ width: '100%' }}>
            <FlexColumn style={{ margin: '0 10px', width: '100%' }}>
              <p>
                {'You are about to delete user '} <b>{user.email}</b>
                {'from system are you sure?'}
              </p>
            </FlexColumn>
          </FlexColumn>
        </>
      }></Modal>
  );
};

export default DeleteProfilePopup;
