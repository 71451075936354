export const actionLoading = (actionType) => {
	return {
		type: actionType
	}
}

export const actionSuccess = (actionType, payload) => {
	return {
		type: actionType,
		payload: payload
	}
}

export const actionFail = (actionType, error) => {
	return {
		type: actionType,
		error: error
	}
}

export const actionClean = (actionType) => {
	return {
		type: actionType
	}
}