import React from 'react';
import { useTranslation } from 'react-i18next';

import Routes from '../routes';
import Nav from './NavigationStyledComponents';
import { FlexColumnCentered } from '../../common/components/ui/CommonStyledComponent';

const NavRoutes = ({ displayRoutes }) => {
  const { t } = useTranslation();
  return (
    <Nav.NavBarContainer clicked={displayRoutes}>
      <Nav.NavBar>
        <Nav.NavItem>
          <Nav.NavStyledNavLink exact={false} to={Routes.Users.users}>
            <FlexColumnCentered>{t('users.label')}</FlexColumnCentered>
          </Nav.NavStyledNavLink>
        </Nav.NavItem>
        <Nav.NavItem>
          <Nav.NavStyledNavLink exact={false} to={Routes.Groups.groups}>
            <FlexColumnCentered>{t('groups.label')}</FlexColumnCentered>
          </Nav.NavStyledNavLink>
        </Nav.NavItem>
        <Nav.NavItem>
          <Nav.NavStyledNavLink exact={false} to={Routes.Reports.reports}>
            <FlexColumnCentered>{t('reports.label')}</FlexColumnCentered>
          </Nav.NavStyledNavLink>
        </Nav.NavItem>
        <Nav.NavItem>
          <Nav.NavStyledNavLink exact={false} to={Routes.Messages.messages}>
            <FlexColumnCentered>{t('messages.label')}</FlexColumnCentered>
          </Nav.NavStyledNavLink>
        </Nav.NavItem>
      </Nav.NavBar>
    </Nav.NavBarContainer>
  );
};

export default NavRoutes;
