import React from 'react';
import PropTypes from 'prop-types';

import { Content, StyledInput, WarningDiv } from '../CommonStyledComponent';
import PhoneInput from '../PhoneField';

const TitledInput = ({title, onChange, value, disabled, type, inputStyle, containerStyle, placeholder, inputIsValid, warningMessage}) => {
	return (
		<Content style={containerStyle}>
			<div className="title">{title}</div>
			<div className="input-container">
				<StyledInput
					placeholder={placeholder}
					style={inputStyle}
					type={type}
					disabled={disabled}
					value={value}
					onChange={onChange} />
			</div>
			{!inputIsValid ? <WarningDiv >{warningMessage}</WarningDiv> : null}
		</Content>
	);
};

TitledInput.propTypes = {
	title: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	inputStyle: PropTypes.object,
	containerStyle: PropTypes.object,
}

TitledInput.defaultProps = {
	value: '',
	disabled: false,
	type: 'text'
}

export default TitledInput;

export const TitledPhoneInput = ({title, onChange, value, props}) => {
	return (
		<Content>
			<div className="title">{title}</div>
			<div className="input-container" style={{padding: '8px 0'}}>
				<PhoneInput
					value={value}
					onChange={onChange} />
			</div>
		</Content>
	);
};

TitledPhoneInput.propTypes = {
	title: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string
}

TitledPhoneInput.defaultProps = {
	value: ''
}
