import React from 'react';
// import { useTranslation } from 'react-i18next';
import Modal from '../../../common/components/ui/PopupWindowLayout';
import { FlexColumn } from '../../../common/components/ui/CommonStyledComponent';

const DeleteReportPopup = ({ report, loading, positiveButtonAction, negativeButtonAction }) => {
    //   const { t } = useTranslation();

    return (
        <Modal
            negativeButtonAction={negativeButtonAction}
            positiveButtonAction={() => positiveButtonAction(report._id)}
            positiveButtonTitle={'Delete'}
            title={'Delete Report'}
            titleStyle={{ paddingBottom: '30px' }}
            popupType={'delete'}
            loading={loading}
            body={
                <>
                    <FlexColumn style={{ width: '100%' }}>
                        <FlexColumn style={{ margin: '0 10px', width: '100%' }}>
                            <p>
                                {'You are about to delete report with status '} <b>{report.status}</b>
                                {' from system are you sure?'}
                                <b>{'After you delete this row you will not be able to access this post report and remove the post!'}</b>
                            </p>
                        </FlexColumn>
                    </FlexColumn>
                </>
            }></Modal>
    );
};

export default DeleteReportPopup;
