import * as actionTypes from '../../../actions/management/actionTypes';
import { updateObject } from '../../../utilities';

const initialState = {
	loading: false,
	error: null
};

const changingPassword = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null
	});
}

const changingPasswordSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: null
	});
}

const changingPasswordFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error
	});
}

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case actionTypes.PROFILE_PASSWORD_CHANGING: return changingPassword(state, action);
		case actionTypes.PROFILE_PASSWORD_CHANGING_SUCCESS: return changingPasswordSuccess(state, action);
		case actionTypes.PROFILE_PASSWORD_CHANGING_FAILED: return changingPasswordFailed(state, action);
		
		default:
			return state;
	}
};

export default reducer;