import * as actionTypes from './actionTypes';
import authApi from '../../api/auth-api';
import jsSha512 from 'js-sha512';
import { updateToken } from '../../../axios';
import * as jwt from 'jsonwebtoken';

const authSuccess = (user, authenticated, loading, error) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		user: user,
		authenticated: authenticated,
		loading: loading,
		error: error
	}
}

const authUserInfoStartLoading = () => {
	return {
		type: actionTypes.AUTH_USER_INFO_START_LOADING
	};
}

const authUserInfoLoaded = (user) => {
	return {
		type: actionTypes.AUTH_USER_INFO_LOADED,
		user: user,
	}
}

const authFailed = (error, email) => {
	localStorage.removeItem('markall_token');
	return {
		type: actionTypes.AUTH_FAILED,
		error: error,
		email: email
	}
}

export const logout = () => {
	localStorage.removeItem('markall_token');
	updateToken();
	return {
		type: actionTypes.AUTH_LOGOUT
	}
}

export const setAuthRedirectPath = (path) => {
	return {
		type: actionTypes.SET_AUTH_REDIRECT_PATH,
		path: path
	};
};


export const authLogin = (authenticated, loading) => {
	return {
		type: actionTypes.AUTH_LOGIN,
		authenticated: authenticated,
		loading: loading
	}
}

const authStartLogout = () => {
	return {
		type: actionTypes.AUTH_START_LOGOUT,
	}
}


const authLogoutSuccess = () => {
	localStorage.removeItem('markall_token');
	updateToken();
	return {
		type: actionTypes.AUTH_LOGOUT,
	}
}

export const authLogout = () => {
	return async dispatch => {
		dispatch(authStartLogout());
		const { status, data, error } = await authApi.logout();
		if (status === 200 && data.ok === 1) {
			return dispatch(authLogoutSuccess());
		}
		else {
			return dispatch(authFailed(error))
		}
	}
}


export const authUserLogin = (email, password, remember) => {
	return async dispatch => {
		dispatch(authLogin(false, true));
		password = jsSha512(password);
		const creds = {
			email: email,
			password: password,
			remember: remember
		}
		const { status, data, error } = await authApi.login(creds);
		if (status === 200 && data.access_token) {
			localStorage.setItem('markall_token', data.access_token);
			updateToken();
			return dispatch(authSuccess(null, true, true, false));
		}
		else {
			return dispatch(authFailed(error, email))
		}
	}
}

export const authCheckState = () => {
	return async dispatch => {
		dispatch(authUserInfoStartLoading());
		const token = localStorage.getItem('markall_token');
		if (!token ) {
			return dispatch(logout());
		}

        const decoded = jwt.decode(token);
        const expiration = decoded.exp;
        if (!expiration || expiration < (new Date().getTime()/1000)) {
			return dispatch(logout());
        }
		else {
			updateToken();
			const { status, data, error } = await authApi.fetchUserInfo();
			if (status === 200) {
				return dispatch(authUserInfoLoaded(data));
			}
			else {
				return dispatch(authFailed(error));
			}
		}
	};
};

export const reloadUserInfo = () => {
	return async dispatch => {
		const { status, data, error } = await authApi.fetchUserInfo();
		if (status === 200) {
			return dispatch(authUserInfoLoaded(data));
		}
		else {
			return dispatch(authFailed(error));
		}
	};
}