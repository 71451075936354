import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/auth/auth-actions';

const Logout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(logout());
	}, []);
	return null;
}

export default React.memo(Logout);