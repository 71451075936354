import styled from 'styled-components';

const FullPageContainer = styled.div`
    position: absolute;
    overflow: auto;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1003;
    display: flex;
    height: fit-content;
    justify-content: center;
    padding: 5rem 0;
`;

const ListContainer = styled.div`
    width: 100%;
    max-width: 652px;
    padding: 0.5rem 0.5rem;
    height: fit-content;

    & > .title {
        font-family: InterUI-SemiBold;
        padding: 1.25rem 0;
        font-size: 36px;
    }

    & > h6 {
        margin:0;
        padding: 16px 0;
    }
`;

const BackBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

 const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--primary-drak-gray);

    & > .title {
        padding-top: 20px;
        font-family: InterUI-SemiBold;
        font-size: 36px;
    }
    
    & > h6 {
        margin: 0;
        padding-top: 25px;
        padding-bottom: 12px;
    }

    & > .bottom-section {
        padding-top: 30px;
        justify-content: flex-end;
    }
`;

const SmallTitle = styled.div`
    font-family: InterUI-SemiBold;
    font-size: 16px;
`;


export default {
    FullPageContainer,
    ListContainer,
    BackBar,
    BoxContainer,
    SmallTitle
};