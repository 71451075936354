import axiosInstance from '../../../../axios';
import UsersRoutes from './routes/index';
import { validate } from '../../../../store/api/schemas/validator';
import { errorSchema, updateResponseSchema } from '../../../../store/api/schemas/common-schemas';

const fethUsers = async (page, limit, requestId, searchUsers) => {
  try {
    const headers = {
      'f-page': page,
      'f-limit': limit,
      requestId: requestId,
    };

    let url = UsersRoutes.users;
    let url2 = UsersRoutes.users + '/totalusers';
    if (searchUsers !== '') {
      url += '?search=' + searchUsers;
      url2 += '?search=' + searchUsers;
    }

    const { status, data, error } = await axiosInstance.get(url, { headers: headers });
    if (data) {
      const { status: status2, data: data2, error: error2 } = await axiosInstance.get(url2, { headers: headers });
      let payload = { users: data.users, metadata: { total: data2.totalUsers, page: page } };
      return { data: payload, status: status2 ? status2 : status, error: error2 ? error2 : error };
    }
  } catch (error) {
    console.error(JSON.stringify(error));
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Users not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const getUser = async (requestId, userId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = UsersRoutes.users;
    url = url + '/' + userId;
    const { data, status, error } = await axiosInstance.get(url, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'User not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const updateUser = async (userId, updateInfo, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = UsersRoutes.users;
    url = url + '/' + userId;

    const { data, status, error } = await axiosInstance.put(url, updateInfo, { headers: headers });

    return { data, status, error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 403:
        message = 'Not permitted';
        break;
      case 404:
        message = 'user not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const deleteUserById = async (userId, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = UsersRoutes.users;
    url = url + '/' + userId;
    const { data, status, error } = await axiosInstance.delete(url, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'User not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const usersApi = {
  fethUsers: fethUsers,
  getUser: getUser,
  updateUser: updateUser,
  deleteUserById: deleteUserById,
};

export default usersApi;
