import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Routes from '../../../components/routes/index';
import { loadReport, cleanReport, editReport } from '../store/reducers/reports-reducer';
import { loadGroup, deleteGroupById, cleanGroup } from '../../groups/store/reducers/groups-reducer';
import { formatDate } from '../../../common/utils/date-utils';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { imagePath } from '../../../common/utils/utils';
import DeleteGroupPopup from '../../groups/components/DeleteGroupPopup';
import { Label, FlexColumn, FlexRowCentered, Spinner, PageTitle, FlexRow } from '../../../common/components/ui/CommonStyledComponent';
import { MButtonBlue, MButtonRed, BackButtonTBlue } from '../../../common/components/ui/Buttons';
import FullPageLayout from '../../../components/PageLayout/FullPageLayout';
import StyledSelect from '../../../common/components/ui/Select';

const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'In review', value: 'in_review' },
  { label: 'Closed', value: 'closed' },
];

const ReportedGroup = ({ history, match }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [status, setStatus] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { loading, error } = useSelector((state) => state.reports);
  const { group, loading: groupLoading, error: groupError } = useSelector((state) => state.groups);

  const getMatchOption = (value) => {
    return statusOptions.find((opt) => opt.value === value);
  };

  let reportId = '';
  if (match.params.id) {
    reportId = match.params.id;
  } else {
    onBack();
  }

  let groupId = '';
  if (match.params.groupId) {
    groupId = match.params.groupId;
  } else {
    onBack();
  }

  useEffect(() => {
    dispatch(loadGroup(groupId));
    dispatch(loadReport(reportId)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setStatus(data.payload.report.status);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanGroup());
      dispatch(cleanReport());
    };
  }, []);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
    if (groupError) {
      addToast(groupError, { appearance: 'error', autoDismiss: true });
    }
  }, [error, groupError]);

  const onBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(Routes.Reports.reports);
    }
  };

  const deleteGroupHandler = () => {
    dispatch(deleteGroupById(groupId)).then((data) => {
      if (!data.error) {
        addToast('Group has been successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        setShowDeletePopup(false);
      }
    });
  };

  const editReportHandler = () => {
    const updateInfo = {
      status,
    };
    dispatch(editReport(reportId, updateInfo)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Report Edited', { appearance: 'success', autoDismiss: true });
      }
    });
  };

  return (
    <FullPageLayout.FullPageContainer>
      <FullPageLayout.ListContainer style={{ maxWidth: '80%' }}>
        <FullPageLayout.BackBar>
          <BackButtonTBlue onClick={onBack}>Back</BackButtonTBlue>
        </FullPageLayout.BackBar>
        <FlexRow>
          {groupLoading ? (
            <FlexRowCentered style={{ padding: '25px 0' }}>
              <Spinner size='2x' />
            </FlexRowCentered>
          ) : (
            <FlexColumn style={{ width: '60%' }}>
              <PageTitle style={{ fontSize: '25px', alignSelf: 'flex-start' }}>{'Reported Group'}</PageTitle>
              {group._id ? (
                <FlexColumn style={{ margin: '0 20px', width: '100%' }}>
                  <FlexColumn style={{ width: '100%', margin: '10px 20px 10px 0' }}>
                    <Label style={{ padding: '5px 0' }}>Name:</Label>
                    <p>{group.name}</p>
                  </FlexColumn>
                  <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                    <Label style={{ padding: '5px 0' }}>Description:</Label>
                    <p>{group.description}</p>
                  </FlexColumn>
                  <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                    <Label style={{ padding: '5px 0' }}>Terms:</Label>
                    <p>{group.terms}</p>
                  </FlexColumn>
                  <FlexRow>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Show To:</Label>
                      <p>{group.showTo}</p>
                    </FlexColumn>
                    <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                      <Label style={{ padding: '5px 0' }}>Last Update:</Label>
                      <p>{formatDate(group.lastUpdate)}</p>
                    </FlexColumn>
                  </FlexRow>
                  <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                    <Label style={{ padding: '5px 0' }}>Avatar:</Label>
                    <RoundProfileImage
                      radius={5}
                      width={400}
                      height={400}
                      imgUrl={imagePath(group.avatar)}
                      withButton={false}
                      withDeleteButton={false}
                    />
                  </FlexColumn>
                </FlexColumn>
              ) : (
                <Label style={{ padding: '5px 0' }}>Group Deleted</Label>
              )}
              <MButtonRed
                onClick={() => setShowDeletePopup(true)}
                style={{
                  width: '150px',
                  margin: '10px 0px',
                }}
                disabled={groupLoading || !group._id}>
                Delete
              </MButtonRed>
              {showDeletePopup ? (
                <DeleteGroupPopup
                  group={group}
                  loading={loading}
                  positiveButtonAction={deleteGroupHandler}
                  negativeButtonAction={() => {
                    setShowDeletePopup(false);
                  }}
                />
              ) : null}
            </FlexColumn>
          )}
          {loading ? (
            <FlexRowCentered style={{ padding: '25px 0' }}>
              <Spinner size='2x' />
            </FlexRowCentered>
          ) : (
            <FlexColumn style={{ width: '40%' }}>
              <PageTitle style={{ fontSize: '25px', alignSelf: 'flex-start' }}>{'Report Status'}</PageTitle>
              <FlexColumn style={{ width: '50%' }}>
                <StyledSelect value={getMatchOption(status)} options={statusOptions} onChange={(choice) => setStatus(choice.value)} />
              </FlexColumn>
              <MButtonBlue
                onClick={() => editReportHandler()}
                style={{
                  width: '150px',
                  margin: '10px 0px',
                }}
                disabled={loading}>
                Save
              </MButtonBlue>
            </FlexColumn>
          )}
        </FlexRow>
      </FullPageLayout.ListContainer>
    </FullPageLayout.FullPageContainer>
  );
};

export default ReportedGroup;
