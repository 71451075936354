import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-style.css';

import Modal from '../../../common/components/ui/PopupWindowLayout';
import { StyledInput, StyledTextArea, Label, FlexColumn } from '../../../common/components/ui/CommonStyledComponent';
import StyledSelect from '../../../common/components/ui/Select';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { getImagePresignedUrl, deleteImage } from '../../../store/actions/files/files-actions';
import ImageUploadPopup from '../../../common/components/ui/ImageUploadPopup';
import { getId, imagePath } from '../../../common/utils/utils';

// const showToOptions = [
//   { label: 'Private', value: 'private' },
//   { label: 'Public', value: 'public' },
// ];

const EditGroupPopup = ({ group, loading, positiveButtonAction, negativeButtonAction }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [name, setName] = useState(group.name || '');
  const [description, setDescription] = useState(group.description || '');
  const [terms, setTerms] = useState(group.terms || '');
  const [showTo, setShowTo] = useState(group.showTo || '');
  const [avatar, setAvatar] = useState(group.avatar);
  const [oldImage, setOldImage] = useState(null);
  const [showImageUploadPopup, setShowImageUploadPopup] = useState(false);

  const getMatchOption = (value) => {
    return showToOptions.find((opt) => opt.value === value);
  };

  const onSave = () => {
    if (!name || name === '') {
      addToast('Name is empty!', { appearance: 'error', autoDismiss: true });
      return;
    }
    const updatedGroup = {
      name,
      description,
      terms,
      showTo,
      avatar,
    };

    positiveButtonAction(group._id, updatedGroup, oldImage);
  };

  const onImageUploadDone = (fileName) => {
    setOldImage(group.avatar);
    setAvatar(fileName);
  };

  const onImageDelete = () => {
    if (avatar) {
      dispatch(deleteImage(avatar, group._id, getId()));
      setAvatar(null);
    }
  };

  const onClose = () => {
    if (avatar && oldImage) {
      dispatch(deleteImage(avatar, group._id, getId()));
    }
    negativeButtonAction();
  };

  return (
    <Modal
      negativeButtonAction={onClose}
      positiveButtonAction={onSave}
      positiveButtonTitle={'Save'}
      title={'Edit Group'}
      titleStyle={{ paddingBottom: '30px' }}
      popupType={'submit'}
      popupStyle={{ top: '55%' }}
      loading={loading}
      body={
        <>
          <FlexColumn style={{ width: '100%' }}>
            <FlexColumn style={{ margin: '0 20px', width: '100%' }}>
              <RoundProfileImage
                radius={5}
                width={190}
                height={140}
                fontSize={'40px'}
                imgUrl={imagePath(avatar)}
                withButton={true}
                withDeleteButton={false}
                deleteClickHandler={onImageDelete}
                changeClickedHandler={() => setShowImageUploadPopup(true)}
              />
              <FlexColumn style={{ width: '100%', margin: '20px 0' }}>
                <Label style={{ padding: '5px 0' }}>Name</Label>
                <StyledInput
                  value={name}
                  onChange={(event) => {
                    if (!event.target.value || event.target.value === '') {
                      addToast('Name is empty!', { appearance: 'error', autoDismiss: true });
                    }
                    setName(event.target.value);
                  }}
                />
              </FlexColumn>
              <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                <Label style={{ padding: '5px 0' }}>Description</Label>
                <StyledTextArea
                  style={{ width: '97%', padding: '0.7rem' }}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FlexColumn>
              <FlexColumn style={{ width: '100%' }}>
                <Label style={{ padding: '5px 0px' }}>Terms</Label>
                <StyledTextArea style={{ width: '97%', padding: '0.7rem' }} value={terms} onChange={(event) => setTerms(event.target.value)} />
              </FlexColumn>
              {/* <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                <Label style={{ padding: '5px 0' }}>Show To</Label>
                <StyledSelect value={getMatchOption(showTo)} options={showToOptions} onChange={(choice) => setShowTo(choice.value)} />
              </FlexColumn> */}
            </FlexColumn>
            {showImageUploadPopup ? (
              <ImageUploadPopup
                closeAction={() => setShowImageUploadPopup(false)}
                onUploadDone={onImageUploadDone}
                getPresignedUrlAction={(fileName, requestId) => dispatch(getImagePresignedUrl(fileName, requestId, group._id, null))}
                deleteImage={(fileName, requestId) => dispatch(deleteImage(fileName, group._id, requestId))}
              />
            ) : null}
          </FlexColumn>
        </>
      }></Modal>
  );
};

export default EditGroupPopup;
