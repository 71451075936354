import React from 'react';
import { ActionCell } from '../../../components/tables/CommonTableComponents';
import { formatDate } from '../../../common/utils/date-utils';
import RoundProfileImage from '../../../common/components/ui/RoundComponents';
import { imagePath } from '../../../common/utils/utils';
import { EllipsisDiv } from '../../../common/components/ui/CommonStyledComponent';

const columns = [
    {
        id: 'code',
        Header: 'Code',
        accessor: 'code',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'description',
        Header: 'Description',
        accessor: 'description',
        Cell: (props) => {
            if (props.value) {
                return <EllipsisDiv>{props.value}</EllipsisDiv>;
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'terms',
        Header: 'Terms',
        accessor: 'terms',
        Cell: (props) => {
            if (props.value) {
                return <EllipsisDiv>{props.value}</EllipsisDiv>;
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'avatar',
        Header: 'Avatar',
        accessor: 'avatar',
        Cell: (props) => {
            if (props.value) {
                return <RoundProfileImage radius={5} width={100} height={100} imgUrl={imagePath(props.value)} withButton={false} withDeleteButton={false} />;
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'createdBy',
        Header: 'Created By',
        accessor: 'createdBy.fullName',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'totalMembers',
        Header: 'Total Members',
        accessor: 'totalMembers',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'showTo',
        Header: 'Show To',
        accessor: 'showTo',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'lastUpdate',
        Header: 'Last Update',
        accessor: 'lastUpdate',
        Cell: (props) => {
            if (props.value) {
                return formatDate(props.value);
            } else {
                return null;
            }
        },
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'Actions',
        Header: 'Actions',
        accessor: (props) => props,
        Cell: (props) => <ActionCell {...props} />,
        disableSortBy: true,
        width: '10%',
    },
];

export default columns;
