export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_USER_INFO_START_LOADING = 'AUTH_USER_INFO_START_LOADING'
export const AUTH_USER_INFO_LOADED = 'AUTH_USER_INFO_LOADED'
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_START_LOGOUT = 'AUTH_START_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

