import axiosInstance from '../../../../axios';
import MessagesRoutes from './routes/index';
import { validate } from '../../../../store/api/schemas/validator';
import { messagesArraySchema } from './schemas/messages-schemas';
import { errorSchema, updateResponseSchema } from '../../../../store/api/schemas/common-schemas';

const fetchMessages = async (page, limit, requestId) => {
  try {
    const headers = {
      'f-page': page,
      'f-limit': limit,
      requestId: requestId,
    };

    let url = MessagesRoutes.messages;

    const { status, data, error } = await axiosInstance.get(url, { headers: headers });
    const { result, e, s } = await validate(messagesArraySchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(JSON.stringify(error));
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happend, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Messages were not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const sendMessage = async (message, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };

    let url = MessagesRoutes.sendMessage;
    message = { subject: message.subject, message: message.text };
    const { status, data, error } = await axiosInstance.post(url, message, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(JSON.stringify(error));
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happend, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Message was not sent';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const messagesApi = {
  fetchMessages: fetchMessages,
  sendMessage: sendMessage,
};

export default messagesApi;
