import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { userInfoSchema, wsTicketSchema } from './schemas/auth-schemas';
import { errorSchema, updateResponseSchema } from './schemas/common-schemas';
import { validate } from './schemas/validator';

import { getId } from '../../common/utils/utils';

const login = async (creds) => {
	try {
		const { status, data, error } = await axiosInstance.post(ServerRoutes.userLogin, creds);
		const { result, e, s } = await validate(updateResponseSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const logout = async () => {
	try {
		const { status, data, error } = await axiosInstance.get(ServerRoutes.userLogout);
		const { result, e, s } = await validate(updateResponseSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const fetchUserInfo = async () => {
	try {
		const headers = {
			requestId: getId()
		}

		const { status, data, error } = await axiosInstance.get(ServerRoutes.userInfo, { headers: headers });
		const { result, e, s } = await validate(userInfoSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		// console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 404:
				message = 'User not found';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const getWebSocketTicket = async (requestId, ent) => {
	try {
		const headers = {
			requestId: requestId,
			ent: ent
		}
		const { status, data, error } = await axiosInstance.get(ServerRoutes.wsTicket, { headers: headers });
		const { result, e, s } = await validate(wsTicketSchema, data, false);
		return { data: result, status: s ? s : status, error: e ? e : error };
	}
	catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message = 'Some error happen, please try again. If the error returns please contact support';
		switch (e.response.status) {
			case 400:
				message = 'Bad request';
				break;
			case 403:
				message = 'Not permited';
				break;
			case 500:
				message = 'Server error. Please contact support';
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
}

const authApi = {
	fetchUserInfo: fetchUserInfo,
	getWebSocketTicket: getWebSocketTicket,
	login: login,
	logout: logout
}

export default authApi;