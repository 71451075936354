import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-style.css"

import { StyledInput, Label, FlexColumn, StyledTextArea } from '../../common/components/ui/CommonStyledComponent';
import { DatePickerContainer } from '../../features/usersManagement/components/CommonStyles';
import EmailInput from '../../common/components/ui/inputs/EmailInput';
import PhoneInput from '../../common/components/ui/PhoneField';
import StyledSelect from '../../common/components/ui/Select';

const FieldsGenerator = ({schema, values, onFieldChange, onDateFieldChange}) => {

	return <FlexColumn style={{ width: '100%' }}>
		{
		schema.map((field, index) => {
			switch (field.type) {
				case 'text': 
					return <FlexColumn key={index}>
						<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
						<StyledInput
							disabled={!field.editable}
							value={values[field.fieldName]}
							onChange={(event) => onFieldChange(field.fieldName, event.target.value)} />
					</FlexColumn>
				case 'email':
					return <FlexColumn key={index}>
						<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
						<EmailInput
							disabled={!field.editable}
							value={values[field.fieldName]}
							onChange={(text) => onFieldChange(field.fieldName, text)}
						/>
					</FlexColumn>
				case 'date':
					return <FlexColumn key={index}>
						<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
						<DatePickerContainer>
							<DatePicker
								disabled={!field.editable}
								placeholderText="Date"
								dateFormat="dd/MM/yyyy"
								onChange={(date) => onDateFieldChange(field.fieldName, date)}
								selected={new Date(values[field.fieldName] || null)}
							/>
						</DatePickerContainer>
					</FlexColumn>
				case 'phone':
					return <FlexColumn key={index}>
						<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
						<PhoneInput
							disabled={!field.editable}
							value={values[field.fieldName]}
							onChange={(value) => onFieldChange(field.fieldName, value)}
							style={{ maxWidth: '270px', margin: '8px 0' }}
						/>
					</FlexColumn>
				case 'textArea':
					return <FlexColumn key={index}>
						<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
						<StyledTextArea
							disabled={!field.editable}
							value={values[field.fieldName]}
							onChange={(event) => onFieldChange(field.fieldName, event.target.value)}
							style={{ maxWidth: '798px' }}
						/>
					</FlexColumn>
				case 'select':
					return <FlexColumn key={index}>
					<Label style={{ padding: '5px 0' }}>{field.displayName}</Label>
					<StyledSelect
						isSearchable={field.isSearchable}
						width={field.width || '250px'}
						disabled={!field.editable}
						options={field.options}
						onInputChange={ (value)=> onFieldChange(field.fieldName + 'Text', value)}
						onChange={(choice) => onFieldChange(field.fieldName + 'Value', choice.value)}
					/>
					</FlexColumn>
				default:
					return null;
			}
		})
	}
</FlexColumn>
}

export default FieldsGenerator;