import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ManagementNavContainer = styled.div`
	padding: 0 40px;
	height: 100%;
`;

export const ManagementNav = styled.nav`
	position: fixed;
	top: 64px;
	left: 0;
	right: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	height: 64px;
	align-items: center;
	box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
	background: white;
	z-index: 998;
`;

export const ManagementNavBar = styled.ul`
	width: 100%;
	padding: 0;
    margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	list-style: none;
	height: 100%;
`;

export const ManagementNavItem = styled.li`
	margin-right: 40px;
	height: calc(100% - 3px); 
    padding: 0; 
    align-items: center; 
    display: flex; 
	a {
		height: 100%;
    	display: flex;
    	align-items: center;
		padding: 0;
		text-decoration: none;
		color: var(--neutral-80p);
		font-size: 16px;
		line-height: 16px;
		font-family: InterUI-Medium
	}
	&:hover > a {
		color: var(--neutral-60p);
	}
`;

const activeClassName = 'man-active-nav';

export const ManagementNavStyledNavLink = styled(NavLink).attrs({
	activeClassName: activeClassName,
})`
  &.${activeClassName} {
	border-bottom: 5px solid var(--primary-blue);
  }
`;

export const PageContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;