import styled from 'styled-components';

export const LoginContainer = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height:  100%;
	margin:  0px auto;
	padding: 0px auto;
	/* background: var(--primary-drak-blue); */
`;

export const FormDiv = styled.form`
	background: white;
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	padding: 105px 124px;
`;