import React from 'react';
import { ActionCell } from '../../../components/tables/CommonTableComponents';
import { formatDate } from '../../../common/utils/date-utils';
import PhoneInput from '../../../common/components/ui/PhoneField';

const columns = [
    {
        id: 'id',
        Header: 'ID',
        accessor: '_id',
        disableSortBy: true,
        width: '14%',
    },
    {
        id: 'email',
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        width: '14%',
    },
    {
        id: 'name',
        Header: 'Full Name',
        accessor: 'fullName',
        disableSortBy: true,
        width: '14%',
    },
    {
        id: 'birthDate',
        Header: 'Date of birth',
        accessor: 'birthDate',
        Cell: (props) => {
            return formatDate(props.value);
        },
        disableSortBy: true,
        width: '14%',
    },
    {
        id: 'phone',
        Header: 'Phone',
        accessor: (props) => {
            return { phone: props.phone, countryCode: props.countryCode };
        },
        Cell: (props) => {
            return <PhoneInput containerStyle={{ width: '210px' }} value={props.value.countryCode + props.value.phone} disabled />;
        },
        disableSortBy: true,
        width: '14%',
    },
    {
        id: 'gender',
        Header: 'Gender',
        accessor: 'gender',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'totalFriends',
        Header: 'Total Friends',
        accessor: 'totalFriends',
        disableSortBy: true,
        width: '10%',
    },
    {
        id: 'Actions',
        Header: 'Actions',
        accessor: (props) => props,
        Cell: (props) => <ActionCell {...props} />,
        disableSortBy: true,
        width: '10%',
    },
];

export default columns;
