import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import {
  StyledInput,
  StyledTextArea,
  Label,
  FlexColumn,
  FlexRowCentered,
  Spinner,
  PageTitle,
} from '../../../common/components/ui/CommonStyledComponent';
import StyledSelect from '../../../common/components/ui/Select';
import { MButtonBlue, BackButtonTBlue } from '../../../common/components/ui/Buttons';
import FullPageLayout from '../../../components/PageLayout/FullPageLayout';
import { createGroup } from '../store/reducers/groups-reducer';
import Routes from '../../../components/routes/index';

const showToOptions = [
  { label: 'Private', value: 'private' },
  { label: 'Public', value: 'public' },
];

const NewGroup = ({ history }) => {
  const [valid, setValid] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [terms, setTerms] = useState('');
  const [showTo, setShowTo] = useState('');
  const [avatar, setAvatar] = useState(null);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { loading, error } = useSelector((state) => state.groups);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error]);

  const getMatchOption = (value) => {
    return showToOptions.find((opt) => opt.value === value);
  };

  const onBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(Routes.Groups.groups);
    }
  };

  const createGroupHandler = () => {
    const createdGroup = {
      name,
      description,
      terms,
      showTo,
      avatar,
    };

    dispatch(createGroup(createdGroup)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Group Created', {
          appearance: 'success',
          autoDismiss: true,
        });
        onBack();
      }
    });
  };

  return (
    <FullPageLayout.FullPageContainer>
      <FullPageLayout.ListContainer style={{ maxWidth: '770px' }}>
        <FullPageLayout.BackBar>
          <BackButtonTBlue onClick={onBack}>Back</BackButtonTBlue>
        </FullPageLayout.BackBar>
        <PageTitle>{'New Group'}</PageTitle>
        <FlexColumn style={{ width: '100%' }}>
          <FlexColumn style={{ margin: '0 20px', width: '100%' }}>
            <FlexColumn style={{ width: '100%', margin: '10px 20px 10px 0' }}>
              <Label style={{ padding: '5px 0' }}>Name</Label>
              <StyledInput
                value={name}
                onChange={(event) => {
                  if (!event.target.value || event.target.value === '') {
                    addToast('Name is empty!', { appearance: 'error', autoDismiss: true });
                    setValid(false);
                    setName(event.target.value);
                  } else {
                    setValid(true);
                    setName(event.target.value);
                  }
                }}
              />
            </FlexColumn>
            <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
              <Label style={{ padding: '5px 0' }}>Description</Label>
              <StyledTextArea
                style={{ width: '96%', padding: '0.85rem' }}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FlexColumn>
            <FlexColumn style={{ width: '100%' }}>
              <Label style={{ padding: '5px 0px' }}>Terms</Label>
              <StyledTextArea style={{ width: '97%', padding: '0.7rem' }} value={terms} onChange={(event) => setTerms(event.target.value)} />
            </FlexColumn>
            <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
              <Label style={{ padding: '5px 0' }}>Show To</Label>
              <StyledSelect isSearchable value={getMatchOption(showTo)} options={showToOptions} onChange={(choice) => setShowTo(choice.value)} />
            </FlexColumn>
          </FlexColumn>
          <MButtonBlue
            onClick={() => createGroupHandler()}
            style={{
              width: '150px',
              margin: '10px 0px',
            }}
            disabled={loading || !valid}>
            Save
          </MButtonBlue>
          {loading ? (
            <FlexRowCentered style={{ padding: '25px 0' }}>
              <Spinner size='2x' />
            </FlexRowCentered>
          ) : null}
        </FlexColumn>
      </FullPageLayout.ListContainer>
    </FullPageLayout.FullPageContainer>
  );
};

export default NewGroup;
