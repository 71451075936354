import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	& > .title {
		padding: 0 0.5rem;
		font-family: InterUI-Regular;
		font-size: 16px;
		line-height: 16px;
		color: var(--neutral-60p);

		:hover {
			cursor: pointer;
		}
	}
`;

const Content = styled.label`
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: ${ props => props.height ? props.height : '25px'};
	width: ${ props => props.width ? props.width : '25px'};
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: ${ props => props.height ? props.height : '25px'};
		width: ${ props => props.width ? props.width : '25px'};
		border: 1px solid var(--neutral-40p);
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: block;
		left: ${ props => props.left ? props.left : '9px'};
		top: ${ props => props.top ? props.top : '5px'};
		width: 5px;
		height: 10px;
		border: solid var(--neutral-20p);
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;


const CheckboxInput = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;

	&:checked ~ .checkmark {
		background-color: white;
	}

	&:checked ~ .checkmark:after {
		border-color: var(--primary-drak-blue);
	}
`;

export const Checkbox = (props) => {
	const checkBox = useRef();

	return (
		<Container>
			<Content 
				style={{...props.ContentStyle}}
				height={props.height} 
				width={props.width}
				top={props.top}
				left={props.left}>
				<CheckboxInput type="checkbox" ref={checkBox} {...props}></CheckboxInput>
				<span className="checkmark"></span>
			</Content>
			{props.text ? <label className="title" style={{...props.textStyle, hover: 'po'}}>{props.text}</label> : null }
		</Container>
	)
}

Checkbox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func
}

Checkbox.defaultProps = {
	onChange: ()=>{}
}