
export const profileSchema = [
    {
        fieldName: 'name',
        displayName: 'Name',
        type: 'text',
        editable: true,
    },
    {
        fieldName: 'company',
        displayName: 'Company',
        type: 'text',
        editable: false,
    },
    {
        fieldName: 'email',
        displayName: 'Email',
        type: 'email',
        editable: true,
    },
    {
        fieldName: 'birthdate',
        displayName: 'Birth Date',
        type: 'date',
        editable: true,
    },
    {
        fieldName: 'phone',
        displayName: 'Phone Number',
        type: 'phone',
        editable: true,
    },
    {
        fieldName: 'description',
        displayName: 'Description',
        type: 'textArea',
        editable: true,
    },
    {
        fieldName: 'country',
        displayName: 'Country',
        type: 'select',
        editable: true,
        options: [{label: 'Israel', value: 'israel'},
        {label: 'United States', value: 'usa',},
        {label: 'Sweden', value: 'sweden'}]
    },
]