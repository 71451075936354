import React, { useState } from 'react';
import Modal from '../../../common/components/ui/PopupWindowLayout';
import { Label, FlexColumn } from '../../../common/components/ui/CommonStyledComponent';
import StyledSelect from '../../../common/components/ui/Select';

const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'In review', value: 'in_review' },
  { label: 'Closed', value: 'closed' },
];

const EditReportPopup = ({ report, loading, positiveButtonAction, negativeButtonAction }) => {
  const [status, setStatus] = useState(report.status || 'new');

  const getMatchOption = (value) => {
    return statusOptions.find((opt) => opt.value === value);
  };

  const onSave = () => {
    const updatedReport = {
      status,
    };
    positiveButtonAction(report._id, updatedReport);
  };

  const onClose = () => {
    negativeButtonAction();
  };

  return (
    <Modal
      negativeButtonAction={onClose}
      positiveButtonAction={onSave}
      positiveButtonTitle={'Save'}
      title={'Edit Report'}
      titleStyle={{ paddingBottom: '30px' }}
      popupType={'submit'}
      //   popupStyle={{ top: '80%' }}
      loading={loading}
      body={
        <>
          <FlexColumn style={{ width: '100%' }}>
            <FlexColumn style={{ margin: '0 20px', width: '100%' }}>
              <Label style={{ padding: '5px 0' }}>
                {report.type === 'group' ? 'Group report with ID ' + report.externalId : 'Post report with ID ' + report.externalId}
              </Label>
              <FlexColumn style={{ width: '100%', marginBottom: '20px' }}>
                <Label style={{ padding: '5px 0' }}>Status</Label>
                <StyledSelect value={getMatchOption(status)} options={statusOptions} onChange={(choice) => setStatus(choice.value)} />
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </>
      }></Modal>
  );
};

export default EditReportPopup;
