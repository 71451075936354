// Profile
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_LOADING_SUCCESS = "PROFILE_LOADING_SUCCESS";
export const PROFILE_LOADING_FAILED = "PROFILE_LOADING_FAILED";
export const PROFILE_CLEAN = "PROFILE_CLEAN";

export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILED = "PROFILE_UPDATE_FAILED";

export const PROFILE_AVATAR_UPDATE_SUCCESS = "PROFILE_AVATAR_UPDATE_SUCCESS";
export const PROFILE_AVATAR_UPDATE_FAILED = "PROFILE_AVATAR_UPDATE_FAILED";

export const PROFILE_PASSWORD_CHANGING = "PROFILE_PASSWORD_CHANGING";
export const PROFILE_PASSWORD_CHANGING_SUCCESS = "PROFILE_PASSWORD_CHANGING_SUCCESS";
export const PROFILE_PASSWORD_CHANGING_FAILED = "PROFILE_PASSWORD_CHANGING_FAILED";

