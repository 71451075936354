import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { fullName, imagePath } from '../../common/utils/utils';
import RoundProfileImage from '../../common/components/ui/RoundComponents';
import Routes from '../routes/index';

export const ProfileMenu = React.memo(({ user, callback }) => {
	const { t } = useTranslation();

	return (
		<ProfileMenuContainer>
			<MenuList>
				<li className="profile">
					<RoundProfileImage
						width={50}
						height={50}
						name={fullName(user)}
						imgUrl={imagePath(user.avatar)} />
					<div className="container">
						<h5>
							{fullName(user)}
						</h5>
						<div className="email">{user.email}</div>
					</div>
				</li>
				<li className="menu-item"><NavLink to={Routes.Management.profile} onClick={callback}>{t('profile.label')}</NavLink></li>
				<li className={'Separator'}></li>
				<li className="logout"><NavLink to={Routes.Common.logout} onClick={callback}>{t('logout.label')}</NavLink></li>
			</MenuList>
		</ProfileMenuContainer>
	);
});


const menuSlide = keyframes`
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
`;

const ProfileMenuContainer = styled.div`
	position: fixed;
	right: 8px;
	top: 65px;
	max-height: 392px;
	max-width: 340px;
	background: #FFFFFF;
	border: 1px solid #C6C6C6;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.39);
	z-index: 999;
	overflow: hidden;
	animation: ${menuSlide} 0.2s linear;
`;

const MenuList = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0;
	align-items: baseline;

	& > li {
		display: flex;
		font-family: InterUI-Regular;
		width: 100%;
		user-select: none;
	}

	& > li > a {
		text-decoration: none;
		font-family: InterUI-Regular;
		width: 100%;
		height: 100%;
		color: var(--neutral-80p);
		padding: 7px 1.875rem;
	}

	& > li.menu-item {
		cursor: pointer;
	}

	& > li.logout {
		cursor: pointer;
	}

	& > li.menu-item:hover {
		background: var(--neutral-20p);
	}

	& > li.logout:hover {
		background: var(--neutral-20p);
	}

	& > li.Separator {
		padding: 0;
		margin: 6px 0;
		width: 100%;
		height: 1px;
		background: var(--neutral-30p);
	}

	& > li.profile {
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: center;
		width: calc(100% - 3.75rem);
		padding: 7px 1.875rem;
	}
	& > li.profile > .container {
		margin: 0px 1rem;
	}

	& > li.profile > .container > h5 {
		margin: 0 0 8px 0;
		align-self: flex-start;
	}
	& > li.profile > .container div.email {
		margin: 0;
		font-size: 14px;
		line-height: 14px;
		color: var(--neutral-50p);
		align-self: start;
	}

	& > li.profile > .container div.owner {
		margin: 0;
		font-size: 16px;
		line-height: 16px;
		color: var(--neutral-50p);
	}
`;

export default ProfileMenuContainer;