import * as actionTypes from '../../actions/auth/actionTypes';
import { updateObject } from '../../utilities';
import Routes from '../../../components/routes/index';

const initialState = {
	email: null,
	user: null,
	authenticated: false,
	error: null,
	loading: false,
	authRedirectPath: null
}

const authStart = (state, action) => {
	return updateObject(state, {
		error: null, 
		user: null,
		authenticated: false, 
		loading: false
	});
}

const authUserInfoStartLoading = (state, action) => {
	return updateObject(state, {
		user: null,
		loading: true
	});
}

const authUserInfoLoaded = (state, action) => {
	return updateObject(state, {
		user: action.user,
		loading: false,
		authenticated: true,
		error: null,
	});
}


const authSuccess = (state, action) => {
	return updateObject(state, {
		user: action.user,
		authenticated: action.authenticated,
		loading: action.loading,
		error: action.error,
		authRedirectPath: Routes.Users.users
	});
}

const authLogin = (state, action) => {
	return updateObject(state , {
		user: null,
		authenticated: action.authenticated,
		loading: action.loading
	})
}

const authFail = (state, action) => {
	return updateObject(state, {
		email: action.email,
		user: null,
		authenticated: false,
		error: action.error,
		loading: false,
		authRedirectPath: '/login'
	});
}

const authStartLogout = (state, action) => {
	return updateObject(state, {
		loading: true,
	});
}

const authLogout = (state, action) => {
	return updateObject(state, {
		user: null,
		authenticated: false,
		error: null,
		loading: false,
		authRedirectPath: '/login'
	});
}

const setAuthRedirectPath = (state, action) => {
	return updateObject(state, {authRedirectPath: action.path});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAILED: return authFail(state, action);
        case actionTypes.AUTH_USER_INFO_START_LOADING: return authUserInfoStartLoading(state, action);
        case actionTypes.AUTH_USER_INFO_LOADED: return authUserInfoLoaded(state, action);
		case actionTypes.AUTH_LOGIN: return authLogin(state,action);
		case actionTypes.AUTH_START_LOGOUT: return authStartLogout(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
		case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default:
            return state;
    }
};

export default reducer;