import React from 'react';
import { useTranslation } from 'react-i18next';

import { ManagementNav, ManagementNavBar, ManagementNavItem, ManagementNavStyledNavLink, ManagementNavContainer } from './ManagementStyles'
import Routes from '../routes/index';

const ManagementNavigation = () => {
	const { t } = useTranslation();
	return (
		<ManagementNav>
			<ManagementNavContainer>
				<ManagementNavBar>
					<ManagementNavItem>
						<ManagementNavStyledNavLink exact={false} to={Routes.Management.profile}>
							{t('profile.label')}
						</ManagementNavStyledNavLink>
					</ManagementNavItem>
				</ManagementNavBar>
			</ManagementNavContainer>
		</ManagementNav>
	);
};

export default ManagementNavigation;