import styled from 'styled-components';

const TableStyles = styled.div`
  width: 100%;
  box-sizing: border-box;
  table {
    border-spacing: 0;
    border: none;
	  font-family: 'InterUI-Regular';
	  color: var(--neutral-90p);
    width: 100%;

    tr {
        height: 80px;
        border: none;
        box-shadow: 0 1px 0 0 #D8D8D8;
    }

    th {
        height: 54px;
        border-bottom: 1px solid var(--neutral-50p);
        margin: auto;
    }

    td {
      margin: 0;
      overflow-wrap: break-word;
      box-sizing: border-box;
      padding: 12px;
      text-align: center;
    }
  }
`

export default TableStyles;