import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const ComponentContainer = styled.div`
	width: 100%;
	max-width: ${props => props.maxWidth || 'none'};
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledProgress = styled.progress`
	-webkit-appearance: none;
	width: 99%;
	position: absolute;
	top: 11px;
	left: 6px;
	height: 4px;
	border: none;
	color: var(--blue-shade-medium);
	background-color: var(--neutral-40p);
	border-radius: 4px;
	&::-webkit-progress-bar {
		background-color: var(--neutral-40p);
		border-radius: 4px;
	}
	&::-webkit-progress-value {
		background-color: var(--blue-shade-medium);
		border-radius: 4px;
	}
	&::-moz-progress-bar { 
		background: var(--blue-shade-medium); 
		border-radius: 4px;
	}
`;

const SliderContainer = styled.div`
	width: 100%;
	position: relative;
`;

const SliderInput = styled.input`
	position: relative;
	-webkit-appearance: none;
	width: 100%;
	height: 4px;
	border-radius: 5px;   
	background: transparent;
	outline: none;
	z-index: 2;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 24px;
		height: 24px;
		border-radius: 50%; 
		background: white;
		box-shadow: 0 0 0 1px rgba(130,136,148,0.16), 0 4px 6px -2px rgba(130,136,148,0.32);
		cursor: pointer;
	}

	&::-webkit-slider-thumb:active {
		box-shadow: 0 0 0 8px rgba(18,100,163,0.3), 0 4px 6px -2px rgba(130,136,148,0.32);
	}


	&::-moz-range-thumb {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: white;
		box-shadow: 0 0 0 1px rgba(130,136,148,0.16), 0 4px 6px -2px rgba(130,136,148,0.32);
		cursor: pointer;
	}
`;

const ScaleContainer = styled.div`
	position: relative;
	width: 98%;
	height: max-content;
	display: flex;
	flex-direction: row;
`;

const ScaleValue = styled.div`
	width: max-content;
	position: absolute;
	left: ${props => props.left || 0};
	transform: ${props => props.translateX || 0};
	display: flex;
	flex-direction: column;
	align-items: center;
	&.first {
		align-items: flex-start;
	}

	&.last {
		align-items: flex-end;
	}

	& > .title {
		padding-top: 0.25rem;
		font-size: 16px;
		line-height: 19px;
		font-family: InterUI-SemiBold;
		color: var(--neutral-80p);
		max-width: 120px;
	}
	& > .subtitle {
		padding-top: 0.25rem;
		font-size: 14px;
		line-height: 20px;
		font-family: InterUI-Medium;
		color: var(--neutral-60p);
		max-width: 180px;
		text-align: center;
	}

	& > .subtitle-first {
		text-align: left;
	}

	& > .subtitle-last {
		text-align: right;
	}
`;

const ScaleLine = styled.div`
	height: 1.25rem;
	width: 1px;
	margin-left: 2px;
	background: var(--additional-light-gray);
`;



export const Slider = React.memo((props) => {
	const [value, setValue] = useState(props.defaultValue);
	const [height, setHeight] = useState(0);
	const sliderRef = useRef(0);
	const allRefs = useRef(props.scale.map(() => React.createRef()));
	useEffect(() => {
		const maxHeight = Math.max.apply(Math, allRefs.current.map(e => {
			return e.current.clientHeight;
		}));
		setHeight(maxHeight);

	}, [props.scale]);

	useEffect(() => {
		setValue(props.defaultValue);
		sliderRef.current.value = props.defaultValue;
	}, [props.defaultValue]);

	useEffect(()=> {
		if (props.disabled) {
			setValue(0);
			sliderRef.current.value = 0;
		}
		else {
			setValue(props.defaultValue);
			sliderRef.current.value = props.defaultValue;
		}
	},[props.disabled]);

	let timer = null;
	const onChange = (event) => {
		props.onChange(event.target.value);
		setValue(event.target.value);
	}

	const onValueChanged = (event) => {
		const value = event.target.value;
		if (props.onChnageDelay) {
			if (timer) {
				clearTimeout(timer);
				timer = null;
			}
			timer = setTimeout(() => {
				props.onValueChanged(value);
				timer = null;
			}, props.onChnageDelay);
		}
		else {
			props.onValueChanged(value);
		}
	}

	return (
		<ComponentContainer maxWidth={props.maxWidth}>
			<SliderContainer>
				<StyledProgress disabled={props.disabled} value={value} max={props.max} min={props.min}></StyledProgress>
				<SliderInput ref={sliderRef} disabled={props.disabled}
					type="range"
					defaultValue={value}
					max={props.max}
					min={props.min}
					step={props.step}
					onChange={(event) => onChange(event)}
					onMouseUp={(event) => onValueChanged(event)} />
			</SliderContainer>
			<ScaleContainer style={{ height: height + 'px' }}>
				{props.scale.map((v, i) => {
					let translateX = 'translateX(-50%)';
					let left = props.scale.length > 1 ? (100 / (props.scale.length - 1)) * i :
						50;
					let className = '';
					if (props.scale.length > 1 && i === 0) {
						translateX = 0;
						className = 'first';
					}
					else if (props.scale.length > 1 && i === props.scale.length - 1) {
						translateX = 'translateX(-100%)';
						className = 'last';
					}
					return (
						<ScaleValue ref={allRefs.current[i]} translateX={translateX} className={className} left={left + '%'} key={v.title}>
							<ScaleLine />
							<div className="title">{v.title}</div>
							{props.showSubtitle ? <div className={"subtitle subtitle-" + className}>{v.subtitle}</div> : null}
						</ScaleValue>
					)
				})}
			</ScaleContainer>
		</ComponentContainer>
	);
});

Slider.propTypes = {
	scale: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		value: PropTypes.value
	})),
	showSubtitle: PropTypes.bool,
	disabled: PropTypes.bool,
	max: PropTypes.number,
	mix: PropTypes.number,
	defaultValue: PropTypes.number,
	onChange: PropTypes.func,
	onValueChanged: PropTypes.func,
	onChnageDelay: PropTypes.number
}

Slider.defaultProps = {
	scale: [],
	defaultValue: 0,
	max: 100,
	min: 0,
	showSubtitle: true,
	onChange: () => { },
	onValueChanged: () => { },
	onChnageDelay: 0
}