import axiosInstance from '../../../../axios';
import ReportsRoutes from './routes/index';
import { validate } from '../../../../store/api/schemas/validator';
import { errorSchema, updateResponseSchema } from '../../../../store/api/schemas/common-schemas';

const fetchReports = async (page, limit, requestId, searchReports, typeFilter, statusFilter, reasonFilter) => {
  try {
    const headers = {
      'f-page': page,
      'f-limit': limit,
      requestId: requestId,
    };

    let url = ReportsRoutes.reports;
    let url2 = ReportsRoutes.reports + '/totalreports';
    url += '?search=' + searchReports;
    url2 += '?search=' + searchReports;
    if (typeFilter !== '') {
      url += '&type=' + typeFilter;
      url2 += '&type=' + typeFilter;
    }
    if (statusFilter !== '') {
      url += '&status=' + statusFilter;
      url2 += '&status=' + statusFilter;
    }
    if (reasonFilter !== '') {
      url += '&reason=' + reasonFilter;
      url2 += '&reason=' + reasonFilter;
    }

    const { status, data, error } = await axiosInstance.get(url, { headers: headers });
    if (data) {
      const { status: status2, data: data2, error: error2 } = await axiosInstance.get(url2, { headers: headers });
      let payload = { reports: data.reports, metadata: { total: data2.totalReports, page: page } };
      return { data: payload, status: status2 ? status2 : status, error: error2 ? error2 : error };
    }
  } catch (error) {
    console.error(JSON.stringify(error));
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Reports not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const getReport = async (requestId, reportId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = ReportsRoutes.reports;
    url = url + '/' + reportId;
    const { data, status, error } = await axiosInstance.get(url, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Report not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const updateReport = async (reportId, updateInfo, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = ReportsRoutes.reports;
    url = url + '/' + reportId;

    const { data, status, error } = await axiosInstance.put(url, updateInfo, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);

    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 403:
        message = 'Not permitted';
        break;
      case 404:
        message = 'Report not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const deleteReportById = async (reportId, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = ReportsRoutes.reports;
    url = url + '/' + reportId;
    const { data, status, error } = await axiosInstance.delete(url, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Report not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const getPost = async (requestId, postId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = ReportsRoutes.reports;
    url = url + '/post/' + postId;
    const { data, status, error } = await axiosInstance.get(url, { headers: headers });
    return { data: data, status: status, error: error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Post not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const deletePostById = async (postId, requestId) => {
  try {
    const headers = {
      requestId: requestId,
    };
    let url = ReportsRoutes.reports;
    url = url + '/post/' + postId;
    const { data, status, error } = await axiosInstance.delete(url, { headers: headers });
    const { result, e, s } = await validate(updateResponseSchema, data, false);
    return { data: result, status: s ? s : status, error: e ? e : error };
  } catch (error) {
    console.error(error);
    const e = errorSchema.cast({ ...error });
    let message = 'Some error happen, please try again. If the error returns please contact support';
    switch (e.response.status) {
      case 404:
        message = 'Post not found';
        break;
      case 500:
        message = 'Server error. Please contact support';
        break;
      default:
        break;
    }
    return {
      error: message,
      status: e.response.status,
    };
  }
};

const reportsApi = {
  fetchReports: fetchReports,
  getReport: getReport,
  updateReport: updateReport,
  deleteReportById: deleteReportById,
  getPost: getPost,
  deletePostById: deletePostById,
};

export default reportsApi;
