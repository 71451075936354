import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../../../store/utilities';

const initialState = {
	messages: [],
	metadata: {total: 0, page: 1},
	loading: false,
	error: null
};

const messagesLoading = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
}

const messagesLoadSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		messages: action.payload.messages,
		metadata: action.payload.metadata
	});
}

const messagesLoadFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error
	});
}

const cleanMessages = (state, action) => {
	return updateObject(state, initialState);
}

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case actionTypes.LOAD_MESSAGES_START: return messagesLoading(state, action);
		case actionTypes.LOAD_MESSAGES_SUCCESS: return messagesLoadSuccess(state, action);
		case actionTypes.LOAD_MESSAGES_FAILED: return messagesLoadFailed(state, action);
		case actionTypes.CLEAN_MESSAGES: return cleanMessages(state, action);
		default:
			return state;
	}
};

export default reducer;