import * as actionTypes from '../../../actions/management/actionTypes';
import { updateObject } from '../../../utilities';

const initialState = {
	profile: null,
	error: null,
	loading: false
};

const profileLoading = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true
	});
}

const profileLoadingSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: null,
		profile: action.profile
	});
}

const profileLoadingFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error,
	});
}

const profileClean = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: null,
		profile: null
	});
}

const profileUpdateSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: null,
		profile: action.profile
	});
}

const profileUpdateFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error
	});
}

const profileUpdateAvatarSuccess = (state, action) => {
	const profile = {...state.profile, avatar: action.avatar};
	return updateObject(state, {
		loading: false,
		error: null,
		profile: profile
	});
}

const profileUpdateAvatarFailed = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.error
	});
}

const reducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case actionTypes.PROFILE_LOADING: return profileLoading(state, action);
		case actionTypes.PROFILE_LOADING_SUCCESS: return profileLoadingSuccess(state, action);
		case actionTypes.PROFILE_LOADING_FAILED: return profileLoadingFailed(state, action);
		case actionTypes.PROFILE_UPDATE_SUCCESS: return profileUpdateSuccess(state, action);
		case actionTypes.PROFILE_UPDATE_FAILED: return profileUpdateFailed(state, action);
		case actionTypes.PROFILE_AVATAR_UPDATE_SUCCESS: return profileUpdateAvatarSuccess(state, action);
		case actionTypes.PROFILE_AVATAR_UPDATE_FAILED: return profileUpdateAvatarFailed(state, action);
		case actionTypes.PROFILE_CLEAN: return profileClean(state, action);
		default:
			return state;
	}
};

export default reducer;