export const messagesSchema = [
    {
        fieldName: 'subject',
        displayName: 'Subject',
        type: 'text',
        editable: true,
    },
    {
        fieldName: 'text',
        displayName: 'Text',
        type: 'textArea',
        editable: true,
    }
]