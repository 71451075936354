const ServerRoutes = {
	userLogin: '/api/v1/auth/login',
	userLogout: '/api/v1/auth/logout',
	userInfo: '/api/v1/auth/userInfo',
	
	userProfile: '/api/v1/management/profile',
	userAvatar: '/api/v1/management/profile/avatar',
	resetPassword: '/api/v1/management/changePassword',

	filestoragePresignedUrl: '/api/v1/filestorage/images/signUrl',
	filestorageDeleteImage: '/api/v1/filestorage/images/delete',
}

export default ServerRoutes;