import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { PageSection } from '../../../components/PageLayout/PageSection';
import GeneralTable from '../../../components/tables/GeneralTable';
import columns from './UsersTableColumns';
import SearchInput from '../../../common/components/ui/inputs/SearchInput';
import { FlexColumn } from '../../../common/components/ui/CommonStyledComponent';
import { loadUsers, cleanUsers, loadUser, editUser, cleanUser, deleteUserById } from '../store/actions/users-actions';
import { ActionsContextMenu } from '../../../components/tables/CommonTableComponents';
import EditProfilePopup from './EditProfilePopup';
import DeleteProfilePopup from './DeleteProfilePopup';

const limit = 100;

const Users = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);

  const { user, loading: userLoading } = useSelector((state) => state.usersManagement.editUser);
  const { users, loading, error, metadata } = useSelector((state) => state.usersManagement.users);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(loadUsers(1, limit, searchValue));
    }, 350);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    return () => {
      dispatch(cleanUsers());
    };
  }, []);

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error]);

  const changePage = (page) => {
    dispatch(loadUsers(page, limit, searchValue));
  };

  const onEditHandler = (data) => {
    dispatch(loadUser(data.props.original._id)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setShowPopup(true);
      }
    });
  };

  const onDeleteHandler = (data) => {
    dispatch(loadUser(data.props.original._id)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        setshowDeletePopup(true);
      }
    });
  };

  const deleteUserHandler = (userId) => {
    dispatch(deleteUserById(userId)).then((data) => {
      if (!data.error) {
        addToast('user has been successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        dispatch(loadUsers(1, limit, searchValue));
        setshowDeletePopup(false);
      }
    });
  };

  const editUserHandler = (userId, updatedInfo, oldImage) => {
    dispatch(editUser(userId, updatedInfo)).then((data) => {
      if (data.error) {
        addToast(data.error, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('User Edited', { appearance: 'success', autoDismiss: true });
        dispatch(loadUsers(1, limit, searchValue));
      }
      setShowPopup(false);
      dispatch(cleanUser());
    });
  };

  const handlers = [
    { title: 'Edit', func: onEditHandler },
    { title: 'Delete', func: onDeleteHandler },
  ];

  return (
    <PageSection style={{ width: '100%', height: 'calc(100% - 65px)', boxSizing: 'border-box' }}>
      <FlexColumn style={{ padding: '20px', position: 'relative' }}>
        <FlexColumn>
          <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} inputStyle={{ borderRadius: '0px', height: '40px' }} />
          {metadata && metadata.total ? <div>Total users: {metadata.total}</div> : null}
        </FlexColumn>
        <GeneralTable
          loading={loading}
          data={users}
          columns={columns}
          limit={limit}
          pages={Math.ceil(metadata.total / limit)}
          page={metadata.page}
          onPageChange={changePage}
        />
        <ActionsContextMenu funcs={handlers} />
        {showPopup ? (
          <EditProfilePopup
            user={user}
            loading={userLoading}
            positiveButtonAction={editUserHandler}
            negativeButtonAction={() => {
              setShowPopup(false);
              dispatch(cleanUser());
            }}
          />
        ) : null}
        {showDeletePopup ? (
          <DeleteProfilePopup
            user={user}
            loading={userLoading}
            positiveButtonAction={deleteUserHandler}
            negativeButtonAction={() => {
              setshowDeletePopup(false);
            }}
          />
        ) : null}
      </FlexColumn>
    </PageSection>
  );
};

export default Users;
