import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { PageSection } from '../../../components/PageLayout/PageSection';
import { FlexColumn, FlexRow } from '../../../common/components/ui/CommonStyledComponent';
import { MButtonBlue } from '../../../common/components/ui/Buttons';
import GeneralTable from '../../../components/tables/GeneralTable';
import columns from './MessagesTableColumns';
import { loadMessages, cleanMessages } from '../store/actions/messages-actions';
import GenericPopup from '../../../components/popups/GenericPopup';
import { messagesSchema } from '../messagesSchema';
import { sendMessage, cleanMessage } from '../store/actions/send-message-actions';

const limit = 30;

const Messages = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);

    const { messages, loading, error, metadata } = useSelector((state) => state.messages);

    const changePage = (page) => {
        dispatch(loadMessages(page, limit));
    };

    useEffect(() => {
        dispatch(loadMessages(1, limit));
        return () => {
            dispatch(cleanMessages());
        };
    }, []);

    useEffect(() => {
        if (error) {
            addToast(error, { appearance: 'error', autoDismiss: true });
        }
    }, [error]);

    const sendMessageHandler = (id, message) => {
        dispatch(sendMessage(message)).then((data) => {
            if (data.error) {
                addToast(data.error, { appearance: 'error', autoDismiss: true });
                setShowPopup(false);
            } else {
                addToast('Message sent succesfully', { appearance: 'success', autoDismiss: true });
                dispatch(loadMessages(1, limit));
                setShowPopup(false);
            }
            dispatch(cleanMessage());
        });
    };

    return (
        <PageSection style={{ width: '100%', height: 'calc(100% - 65px)', boxSizing: 'border-box' }}>
            <FlexColumn style={{ padding: '20px', position: 'relative' }}>
                <FlexRow style={{ width: '200px', alignSelf: 'flex-end' }}>
                    <MButtonBlue onClick={() => setShowPopup(true)}>New Message</MButtonBlue>
                </FlexRow>
                <GeneralTable loading={loading} data={messages} columns={columns} limit={limit} pages={Math.ceil(metadata.total / limit)} page={metadata.page} onPageChange={changePage} />
            </FlexColumn>
            {showPopup ? (
                <GenericPopup
                    title={'New Message'}
                    positiveButtonTitle={'Send'}
                    entity={{ subject: '', text: '' }}
                    entitySchema={messagesSchema}
                    positiveButtonAction={sendMessageHandler}
                    negativeButtonAction={() => {
                        setShowPopup(false);
                    }}
                />
            ) : null}
        </PageSection>
    );
};

export default Messages;
