import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './Login';
import Routes from '../routes';

const LoginNavigation = () => {
	return (
		<Switch>
			<Route path={Routes.Common.login} component={Login} exact={true}></Route>
			<Redirect from="/" to="/login" />
		</Switch>
	)
}

export default LoginNavigation;