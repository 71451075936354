import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Spinner } from './CommonStyledComponent';


const Container = styled.div`
	max-width: ${props => props.maxWidth ? props.maxWidth : null};
	width: 100%;
    height: 100%;
    background: #ffffffaa;
    position: absolute;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
`;

const SpinnerContainer = styled.div`
	padding: 0.5rem 1.5rem;
	display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 5px;
	border: 1px solid var(--neutral-20p);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(181,181,181,1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(181,181,181,1);
	box-shadow: 0px 0px 10px 0px rgba(181,181,181,1);
	& * {
		margin: 0.5rem;
	}
`;

const LoadingComponent = (props) => {
	return (
		<>
			{props.loading ? <Container>
				<SpinnerContainer>
					<Spinner />
					<span>{props.loadingText}</span>
				</SpinnerContainer>
			</Container> : null}
		</>
	)
}

LoadingComponent.propsTypes = {
	loading: PropTypes.bool
}

LoadingComponent.defaultProps = {
	loadingText: 'loading ...'
}

export default LoadingComponent;

const FloatingSpinnerContainer = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 50%);
	padding: 20px;
    background: #ffffff;
	border-radius: 5px;
	z-index: 1000;
`;

export const FloatingSpinner = ({ loading, containerStyle, spinnerStyle, size }) => {
	return (
		<>
			{loading ?
				<FloatingSpinnerContainer style={containerStyle}>
					<Spinner size={size} style={{...spinnerStyle, color: 'var(--blue-shade-dark)'}}/>
				</FloatingSpinnerContainer> : null}
		</>
	);
}

FloatingSpinner.propsTypes = {
	loading: PropTypes.bool,
	containerStyle: PropTypes.object,
	spinnerStyle: PropTypes.object,
	size: PropTypes.string
}

FloatingSpinner.defaultProps = {
	loading: false,
	containerStyle: {},
	spinnerStyle: {},
	size: '1x'
}