import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import EditComponent from '../../../components/forms/EditComponent';
import { profileSchema } from '../profileSchema';
import Routes from '../../../components/routes/index';
import { loadUser, editUser, cleanUser } from '../store/actions/users-actions';

const EditProfile = ({ history, match }) => {
	const dispatch = useDispatch();
	const { user, loading } = useSelector(state => state.usersManagement.editUser);
	const { addToast } = useToasts();

	let userId = '';
	try {
		userId = match.params.id;
	}
	catch (err) {
		history.replace(Routes.Management.profile);
	}

	useEffect(() => {
		dispatch(loadUser(userId)).then((data) => {
			if (data.error) {
				addToast(data.error, { appearance: 'error', autoDismiss: true });
				onBack();
			}
		});

		return () => {
			dispatch(cleanUser());
		}
	}, []);

	const onBack = () => {
		if (history.length > 2) {
			history.goBack();
		}
		else {
			history.replace(Routes.Management.profile);
		}
	}

	const editUserHandler = (userId, updateInfo) => {
		dispatch(editUser(userId, updateInfo)).then((data) => {
			if (data.error) {
				addToast(data.error, { appearance: 'error', autoDismiss: true });
			} else {
				addToast('User Edited', { appearance: 'success', autoDismiss: true });
				onBack();
			}

		})
	}

	return <EditComponent
		entitySchema={profileSchema}
		entity={user}
		loading={loading}
		onEdit={editUserHandler}
		onBack={onBack}
		title={"Edit User"}
	/>
}

export default EditProfile;