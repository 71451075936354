import styled, {keyframes} from 'styled-components';

const fade = keyframes`
	from {
		opacity: 0.01;
	}
	to {
		opacity: 1;
	}
`;

export const PageSection = styled.div`
	padding-top: 65px;
	animation: ${fade} 0.3s linear;
`;
