import authReducer from './auth/auth-user-reducer';
import managementReduceer from './management/management-reducer';
import usersManagementReducer from '../../features/usersManagement/store/reducers/management-reducer';
import groupsReducer from '../../features/groups/store/reducers/groups-reducer';
import reportsReducer from '../../features/Reports/store/reducers/reports-reducer';
import postReducer from '../../features/Reports/store/reducers/post-reducer';
import messagesReducer from '../../features/messages/store/reducers/messages-reducer';
import sendMessageReducer from '../../features/messages/store/reducers/send-message-reducer';

export const reducers = {
  auth: authReducer,
  management: managementReduceer,
  usersManagement: usersManagementReducer,
  groups: groupsReducer,
  reports: reportsReducer,
  post: postReducer,
  messages: messagesReducer,
  sendMessage: sendMessageReducer,
};
