import { createSlice } from '@reduxjs/toolkit';

import { getId } from '../../../../common/utils/utils';
import reportsApi from '../api/reports-api';

const initialState = {
  reports: [],
  metadata: { total: 0, page: 1 },
  report: {},
  loading: false,
  error: null,
};

const _loadStart = (state, action) => {
  state.loading = true;
  state.error = null;
};

const _loadFailed = (state, action) => {
  const { error } = action.payload;
  state.loading = false;
  state.error = error;
};

const _loadReportsSuccess = (state, action) => {
  const { reports, metadata } = action.payload;
  state.loading = false;
  state.reports = reports;
  state.metadata = metadata;
};

const _cleanReports = (state) => {
  state = initialState;
};

const _loadReportSuccess = (state, action) => {
  const { report } = action.payload;
  state.loading = false;
  state.report = report;
};

const _editReportSuccess = (state, action) => {
  state.loading = false;
};

const _cleanReport = (state) => {
  state = initialState;
};

const _deleteReportSuccess = (state, action) => {
  state.loading = false;
};

const reports = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    loadStart: _loadStart,
    loadFailed: _loadFailed,
    loadReportsSuccess: _loadReportsSuccess,
    cleanReports: _cleanReports,
    loadReportSuccess: _loadReportSuccess,
    editReportSuccess: _editReportSuccess,
    cleanReport: _cleanReport,
    deleteReportSuccess: _deleteReportSuccess,
  },
});

const { actions, reducer } = reports;
export const {
  loadStart,
  loadFailed,
  loadReportsSuccess,
  updateReportsSuccess,
  cleanReports,
  loadReportSuccess,
  editReportSuccess,
  cleanReport,
  deleteReportSuccess,
} = actions;
export default reducer;

export const reportsSelector = (state) => state.reports;

export const loadReports = (page = 1, limit = 6, searchValue = '', typeFilter, statusFilter, reasonFilter) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { status, data, error } = await reportsApi.fetchReports(page, limit, getId(), searchValue, typeFilter, statusFilter, reasonFilter);
    if (status === 200) {
      return dispatch(loadReportsSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const loadReport = (reportId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { status, data, error } = await reportsApi.getReport(getId(), reportId);
    if (status === 200) {
      return dispatch(loadReportSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const editReport = (reportId, updateInfo) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await reportsApi.updateReport(reportId, { ...updateInfo }, getId());
    if (status === 200 && data) {
      return dispatch(editReportSuccess(data));
    } else {
      return dispatch(loadFailed(error));
    }
  };
};

export const deleteReportById = (reportId) => {
  return async (dispatch) => {
    dispatch(loadStart());
    const { data, status, error } = await reportsApi.deleteReportById(reportId, getId());
    if (status === 200 && data) {
      return dispatch(deleteReportSuccess());
    } else {
      return dispatch(loadFailed(error));
    }
  };
};
