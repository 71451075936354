import * as actionTypes from '../actions/actionTypes';
import usersReducer from './users/users-reducer';
import editUserReducer from './users/edit-user-reducer';
import deleteUserReducer from './users/delete-user-reducer';

const initialState = {
  users: usersReducer(undefined, {}),
  editUser: editUserReducer(undefined, {}),
  deleteUser: deleteUserReducer(undefined, {}),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USERS_START:
    case actionTypes.LOAD_USERS_SUCCESS:
    case actionTypes.LOAD_USERS_FAILED:
    case actionTypes.CLEAN_USERS:
      return { ...state, users: usersReducer(state.users, action) };

    case actionTypes.LOAD_USER_START:
    case actionTypes.LOAD_USER_SUCCESS:
    case actionTypes.LOAD_USER_FAILED:
    case actionTypes.EDIT_USER_START:
    case actionTypes.EDIT_USER_SUCCESS:
    case actionTypes.EDIT_USER_FAILED:
    case actionTypes.CLEAN_USER:
      return { ...state, editUser: editUserReducer(state.editUser, action) };

    default:
      return state;
  }
};

export default reducer;
