import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import loopa from '../../../../assets/icons/loopa.svg';

import { FlexRow, StyledInput } from '../CommonStyledComponent';

const SearchInput = ({style, inputStyle, placeHolder, value, onChange}) => {
	return (
		<FlexRow style={{ position: 'relative', alignItems: 'center', ...style }}>
			<StyledInput 
				value={value}
				onChange={onChange}
				placeholder={placeHolder} 
				style={{...inputStyle}}></StyledInput>
			{
				value !== '' ?
				<Cross onClick={(e) => {
					e.target.value = ''
					onChange(e)}
				}>&#10005;</Cross>
				: <Loopa src={loopa} alt="" />
			}
		</FlexRow>
	);
};

export default SearchInput;

SearchInput.propTypes = {
	style: PropTypes.object,
	inputStyle: PropTypes.object,
	placeHolder: PropTypes.string,
	value: PropTypes.any, 
	onChange: PropTypes.func
}

SearchInput.defaultProps = {
	style: { width: '300px', margin: '0'},
	inputStyle: { borderRadius: '22px' },
	placeHolder: "Search"
}

const Loopa = styled.img`
	position: absolute;
	right: 15px;
`;

const Cross = styled.div`
	position: absolute;
	right: 15px;
	font-size: 20px;
	cursor: pointer;
`;