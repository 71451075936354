import { actionLoading, actionSuccess, actionFail, actionClean } from '../../../../store/actions/common/common-actions';
import * as actionTypes from './actionTypes';
import { getId } from '../../../../common/utils/utils';
import messagesApi from '../api/messages-api';

export const loadMessages = (page=1, limit=6) => {
	return async dispatch => {
		dispatch(actionLoading(actionTypes.LOAD_MESSAGES_START));
		const { status, data, error } = await messagesApi.fetchMessages(page, limit, getId());
		if ( status === 200 ){
			return dispatch(actionSuccess(actionTypes.LOAD_MESSAGES_SUCCESS, data));
		}
		else {
			return dispatch(actionFail(actionTypes.LOAD_MESSAGES_FAILED, error))
		}
	}
}

export const cleanMessages = () => {
	return actionClean(actionTypes.CLEAN_MESSAGES);
}