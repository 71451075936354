import React from 'react';
import PropTypes from 'prop-types';

import GeneralTableStyles from './GeneralTableStyles';
import Table from './Table';

const GeneralTable = ({ data, columns, limit, pages, loading, page, onPageChange, onSort }) => {

        const columnsArray = React.useMemo( () => columns, [columns]);
        const dataArray = React.useMemo( () => data, [data]);
        
        return (
            <GeneralTableStyles>
                <Table 
                    loading={loading} 
                    columns={columnsArray} 
                    data={dataArray} 
                    limit={limit} 
                    pages={pages} 
                    currentPage={page - 1}
                    onPageChange={onPageChange}
                    onSort={onSort}
                    />
            </GeneralTableStyles>
        )
}

GeneralTable.propTypes = {
	data: PropTypes.array,
    columns: PropTypes.array,
    limit: PropTypes.number,
    totalPageButtons: PropTypes.number,
    loading: PropTypes.bool,
    onSort: PropTypes.func,
}

GeneralTable.defaultProps = {
    data: [],
    columns: [],
    limit: 5,
    totalPageButtons: 3,
    loading: false,
    onSort: () => {}
}

export default GeneralTable;