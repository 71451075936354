import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Routes from '../routes/index';
import Profile from './Profile/Profile';
import { PageSection } from '../PageLayout/PageSection';
import ManagementNavigation from './ManagementComponents';

const Management = () => {
	return (
		<PageSection>
			<ManagementNavigation>Navigation</ManagementNavigation>
			<Switch>
				<Route path={Routes.Management.profile} component={Profile} exact></Route>
				<Redirect to={Routes.Management.profile}/>
			</Switch>
		</PageSection>
	);
};

export default Management;