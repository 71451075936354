import React from 'react';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types';

const PhoneInput = (props) => {
	return (
		<ReactPhoneInput
			autoFormat={false}
			inputStyle={{
				borderRadius: 0,
				width: '100%',
				height: '44px',
				borderColor: props => props.disabled ? 'var(--neutral-20p)' : 'var(--neutral-30p)',
				fontFamily: 'InterUI-Regular',
				fontSize: '16px',
				lineHight: '16px',
				color: 'var(--neutral-80p)'
			}}
			buttonStyle={{
				background: 'transparent',
				borderColor: 'var(--neutral-30p)'
			}}
			{...props}/>

	);
}

PhoneInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func
}

PhoneInput.defaultProps = {
	onChange:()=>{}
}

export default PhoneInput