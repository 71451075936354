import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    font-family: 'InterUI-Regular';
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 100%;
`;

const PaginationButton = styled.button`
    cursor: pointer;
    border: 1px solid #EAEAEA;
    height: 42px;
    width: 42px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'InterUI-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    outline: none;
    background: ${props => props.clicked ? '#3BA6D5' : 'none'};

    &:hover{
        background-color:  ${props => props.disabled ? 'none' :  '#92DAF9'};
    }
`;

const Flex = styled.div`
    display: flex;
    flex-direction: ${props => props.col ? 'column' : 'row'};
    align-items: ${props => props.alignCenter ? 'center' : 'start'};
    justify-content: ${props => props.justifyCenter ? 'center' : 'start'};
`;

const PageCounter = ({pageIndex, pageCount }) => {
    return (
        <Flex style={{marginTop: '4px', fontSize: '12px'}}>
            <div style={{marginRight: '4px'}}>Page</div>
            <strong>
                {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount}
            </strong>
        </Flex>
    )
}

const PageButtons = ({pageIndex, pageCount, onPageChange, totalPageButtons}) => {
    let pagesButtons = [];
    let pagesOnEachSide = Math.floor(totalPageButtons / 2);
    let leftSideDifference = Math.max(0, pagesOnEachSide + pageIndex + 1 - pageCount);
    let rightSideDifference = Math.max(0,  pagesOnEachSide - pageIndex);
    for(let i = Math.max(1, pageIndex + 1 - pagesOnEachSide - leftSideDifference); i <= Math.min(pageIndex + 1 + pagesOnEachSide + rightSideDifference, pageCount); i++){
        pagesButtons.push(<PaginationButton clicked={i === pageIndex + 1} key={i} onClick={() => onPageChange(i)}>{i}</PaginationButton>);
    }
    return pagesButtons;
}

const Pagination = ({onPageChange, pageIndex, pageCount, totalPageButtons}) => {

return (        
    <PaginationContainer>
        <Flex col alignCenter>
            <Flex>
                <PaginationButton onClick={() => onPageChange(1)} disabled={pageIndex === 0}>
                    {'<<'}
                </PaginationButton>
                <PaginationButton onClick={() => onPageChange(pageIndex)} disabled={pageIndex === 0}>
                    {'<'}
                </PaginationButton>
                <PageButtons 
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    onPageChange={onPageChange}
                    totalPageButtons={totalPageButtons}/>
                <PaginationButton onClick={() => onPageChange(pageIndex + 2)} disabled={pageIndex === pageCount - 1 }>
                    {'>'}
                </PaginationButton>
                <PaginationButton onClick={() => onPageChange(pageCount)} disabled={pageIndex === pageCount - 1 }>
                    {'>>'}
                </PaginationButton>
            </Flex>
            <PageCounter
                pageIndex={pageIndex}
                pageCount={pageCount}
            />
        </Flex>
    </PaginationContainer>)
}

export default Pagination;