import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  margin: 8px 0;
  width: 100%;
  max-width: 270px;
`;

export const PackedRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 6px 40px;
  box-sizing: border-box;
`;
