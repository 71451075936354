import * as actionTypes from '../actionTypes';
import profileApi from '../../../api/profile-api';
import jsSha512 from 'js-sha512';

const resetPassword = () => {
	return {
		type: actionTypes.PROFILE_PASSWORD_CHANGING,
	}
}

const resetPasswordSuccess = () => {
	return {
		type: actionTypes.PROFILE_PASSWORD_CHANGING_SUCCESS,
	}
}

const resetPasswordFailed= (error) => {
	return {
		type: actionTypes.PROFILE_PASSWORD_CHANGING_FAILED,
		error: error
	}
}

export const changePassword = (passwords, requestId) => {
	return async dispatch => {
		dispatch(resetPassword());
		const hashedPasswords = {
			oldPassword: jsSha512(passwords.oldPassword),
			newPassword: jsSha512(passwords.newPassword)
		}
		const { status, data, error } = await profileApi.resetPassword(hashedPasswords, requestId);
		if (status === 200 && data.ok === 1) {
			return dispatch(resetPasswordSuccess());
		}
		else {
			return dispatch(resetPasswordFailed(error))
		}
	}
}