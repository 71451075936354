import React, { useState } from 'react';

import Modal from '../../common/components/ui/PopupWindowLayout';
import FieldsGenerator from '../../components/forms/FieldsGenerator';

const GenericPopup = ({entity, entitySchema, title, positiveButtonTitle, loading, positiveButtonAction, negativeButtonAction }) => {
    
    const [values, setValues] = useState(entity);

    const onTextChange = (fieldName, text) => {
        const vals = { ...values };
        vals[fieldName] = text;
        setValues(vals)
    }

    const onDateChange = (fieldName, date) => {
        const vals = { ...values };
        vals[fieldName] = new Date(date).toString();
        setValues(vals);
    }

    return (
        <Modal
            negativeButtonAction={negativeButtonAction}
            positiveButtonAction={() => positiveButtonAction(entity._id, values)}
            positiveButtonTitle={positiveButtonTitle}
            title={title}
            titleStyle={{ paddingBottom: '30px' }}
            popupType={'submit'}
            loading={loading}
            body={
                <>
                  <FieldsGenerator 
                    schema={entitySchema}
                    values={values}
                    onFieldChange={onTextChange}
                    onDateFieldChange={onDateChange}
                  />  
                </>
            }></Modal>
    );
}

export default GenericPopup;