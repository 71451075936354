const dev = {
  apiGateway: {
    API_SERVER_URL: '',
    IMAGE_STORAGE_URL: 'https://mass-storage-test.s3.eu-central-1.amazonaws.com/',
  },
};

const int = {
  apiGateway: {
    API_SERVER_URL: 'http://localhost:8080',
    IMAGE_STORAGE_URL: 'https://markall-dev.s3.eu-central-1.amazonaws.com/',
  },
};

const prod = {
  apiGateway: {
    API_SERVER_URL: 'https://cmsapi.markallapp.com',
    IMAGE_STORAGE_URL: 'https://markall-dev.s3.eu-central-1.amazonaws.com/',
  },
};

let config = dev;
if (process.env.REACT_APP_STAGE.trim() === 'production') {
  config = prod;
} else if (process.env.REACT_APP_STAGE.trim() === 'int') {
  config = int;
}

export default {
  APP_VERSION: 0.1,
  // Add common config values here
  ...config,
};
