import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import trash from '../../../assets/icons/trash.svg';
import upload from '../../../assets/icons/upload.svg';

import Modal from './PopupWindowLayout';
import { FlexColumn, FlexRow } from './CommonStyledComponent';
import { getId, getFileExt } from '../../utils/utils';
import { Slider } from '../ui/Slider';

const dropZoneStyle = {
  overflow: 'hidden',
  minHeight: '300px',
  borderStyle: 'dashed',
  background: 'var(--neutral-20p)',
};

const dropZoneActiveStyle = {
  borderColor: 'var(--secondary-green)',
  color: 'var(--secondary-green)',
};

const dropZoneRejectStyle = {
  borderColor: 'var(--secondary-red)',
};

const inputLabelStyle = {
  color: 'var(--neutral-40p)',
};

const OnPreviewButton = styled.button`
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  align-self: center;
  margin-top: 10px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const PreviewComponent = ({ onUpload, oldCrop, oldZoom, aspect, ...props }) => {
  const [crop, setCrop] = useState(oldCrop ? oldCrop : { x: 0, y: 0 });
  const [zoom, setZoom] = useState(oldZoom ? oldZoom : 1);
  const [cropOptions, setCropOptions] = useState(null);

  useEffect(() => {
    if (props.hasError) {
      props.fileWithMeta.cancel();
      props.fileWithMeta.remove();
    }
  }, [props.hasError]);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCropOptions(croppedAreaPixels);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  return (
    <FlexColumn style={{ padding: '20px' }}>
      <div style={{ width: props.previewWidth, height: props.previewHeight, position: 'relative' }}>
        <Cropper
          image={props.meta.previewUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      {props.loading ? <progress style={{ width: '100%', marginTop: '10px' }} value={props.meta.percent} max='100'></progress> : null}
      {!props.loading && props.meta.status !== 'done' ? (
        <div style={{ marginTop: '10px' }}>
          <Slider defaultValue={parseFloat(zoom) - 1} max={2.0} min={0.0} onChange={(v) => setZoom(parseFloat(v) + 1)} step={0.01} />
        </div>
      ) : null}
      <FlexRow style={{ justifyContent: 'space-around' }}>
        {props.meta.status !== 'done' ? (
          <OnPreviewButton
            onClick={() => {
              props.fileWithMeta.cropOptions = cropOptions;
              onUpload(props.fileWithMeta, cropOptions, { oldCrop: crop, oldZoom: zoom });
            }}>
            <img src={upload} alt='' />
          </OnPreviewButton>
        ) : null}
        <OnPreviewButton
          onClick={() => {
            props.fileWithMeta.cancel();
            props.fileWithMeta.remove();
          }}>
          <img src={trash} alt='' />
        </OnPreviewButton>
      </FlexRow>
    </FlexColumn>
  );
};

const ImageUploadPopup = ({ getPresignedUrlAction, deleteImage, onUploadDone, closeAction, canvasWidth, canvasHeight, aspect, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [hasError, setHasError] = useState(true);
  const [cropParams, setCropParams] = useState({ oldCrop: null, oldZoom: null });
  const { t } = useTranslation();
  useEffect(() => {
    window.onbeforeunload = () => {
      return 'Are you sure you want to leave?';
    };
    return () => {
      window.onbeforeunload = null;
    };
  });

  const getUploadParams = async (params) => {
    const { data } = await getPresignedUrlAction(getId() + '.' + getFileExt(params.file.name), getId());
    setFileName(data.key);
    const croppedImage = await getCroppedImg(URL.createObjectURL(params.file), params.cropOptions, canvasWidth, canvasHeight);
    return {
      body: croppedImage,
      url: data.url,
      method: 'PUT',
    };
  };

  const handleChangeStatus = ({ meta }, status) => {
    setHasError(false);
    switch (status) {
      case 'rejected_file_type':
      case 'rejected_max_files':
      case 'error_file_size':
      case 'error_validation':
      case 'error_upload_params':
      case 'exception_upload':
      case 'error_upload':
        console.log('filesize');
        setHasError(true);
        setLoading(false);
        setFileName(null);
        break;
      case 'aborted':
      case 'removed':
        setHasError(true);
        setLoading(false);
        setFileName(null);
        break;
      case 'uploading':
        setHasError(false);
        setLoading(true);
        break;
      case 'done':
        setLoading(false);
        if (fileName) {
          onUploadDone(!hasError ? fileName : '');
        }
        closeAction();
        break;
      case 'preparing':
      case 'ready':
      case 'started':
      case 'getting_upload_params':
      case 'restarted':
      case 'headers_received':
      default:
        break;
    }
  };

  const onDone = () => {
    closeAction();
  };

  return (
    <Modal
      popupStyle={{ maxWidth: restProps.bodyMaxWidth }}
      closeOnBackdrop={false}
      positiveButtonDisabled={loading}
      negativeButtonAction={closeAction}
      positiveButtonAction={onDone}
      hasNegativeButton={false}
      positiveButtonTitle={'Close'}
      title={t('upload-image.label')}
      popupType={'submit'}
      body={
        <Dropzone
          styles={{
            dropzone: dropZoneStyle,
            inputLabel: inputLabelStyle,
            dropzoneActive: dropZoneActiveStyle,
            dropzoneReject: dropZoneRejectStyle,
          }}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          SubmitButtonComponent={null}
          PreviewComponent={(props) => (
            <PreviewComponent
              previewWidth={restProps.previewWidth}
              previewHeight={restProps.previewHeight}
              cropWidth={canvasWidth}
              cropHeight={canvasHeight}
              hasError={hasError}
              {...props}
              {...cropParams}
              aspect={aspect}
              loading={loading}
              onUpload={(params, cropOptions, crop) => {
                setCropParams(crop);
                params.restart();
              }}
            />
          )}
          multiple={false}
          maxFiles={1}
          autoUpload={false}
          maxSizeBytes={4194304}
          inputContent={(files, extra) => {
            return extra.reject ? t('image-files-only.label') : t('drag-files-or-click.label');
          }}
          accept='image/*'
        />
      }></Modal>
  );
};

ImageUploadPopup.propTypes = {
  closeAction: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
  getPresignedUrlAction: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  bodyMaxWidth: PropTypes.string,
  previewWidth: PropTypes.string,
  previewHeight: PropTypes.string,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  aspect: PropTypes.number,
};

ImageUploadPopup.defaultProps = {
  canvasWidth: 200,
  canvasHeight: 200,
  aspect: 1,
  bodyMaxWidth: '400px',
  previewWidth: '320px',
  previewHeight: '320px',
};

export default ImageUploadPopup;

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop, canvasWidth, canvasHeight) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvasWidth, canvasHeight);

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg');
  });
}
