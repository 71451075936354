import React, { useState, useEffect } from 'react';

import { FlexColumn, FlexRowCentered, Spinner, PageTitle } from '../../common/components/ui/CommonStyledComponent';
import { MButtonBlue, BackButtonTBlue } from '../../common/components/ui/Buttons';
import FullPageLayout from '../PageLayout/FullPageLayout';
import FieldsGenerator from './FieldsGenerator';

const EditComponent = ({ entity, entitySchema, loading, onEdit, onBack, title }) => {
	const [values, setValues] = useState(entity);

	const onTextChange = (fieldName, text) => {
        const vals = { ...values };
        vals[fieldName] = text;
        setValues(vals)
	}
	
	useEffect(()=> {
		if(entity){
			setValues(entity);
		}
	},[entity])

    const onDateChange = (fieldName, date) => {
        const vals = { ...values };
        vals[fieldName] = new Date(date).toString();
        setValues(vals);
    }
	return (   <FullPageLayout.FullPageContainer>
                { loading ?
					<FlexRowCentered style={{ padding: '25px 0' }}>
						<Spinner size="2x" />
					</FlexRowCentered> 
					:
                    
                    <FullPageLayout.ListContainer style={{ maxWidth: '770px' }}>
                        <FullPageLayout.BackBar>
                            <BackButtonTBlue onClick={onBack} disabled={loading}>Back</BackButtonTBlue>
                        </FullPageLayout.BackBar>
                        <PageTitle>{title}</PageTitle>
                        <FlexColumn style={{ width: '100%' }}>
                            <FieldsGenerator
                                schema={entitySchema}
                                values={values}
                                onFieldChange={onTextChange}
                                onDateFieldChange={onDateChange}
                            />
                            <MButtonBlue 
                                onClick={() => onEdit(values)}
                                style={{width: '150px', margin: '10px 0px'}}
                            >Save</MButtonBlue>
                        </FlexColumn>
                    </FullPageLayout.ListContainer> }
                    </FullPageLayout.FullPageContainer> 
    );
}

export default EditComponent;