import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { fullName, imagePath } from '../../common/utils/utils';
import RoundProfileImage, { RoundNameLogo, RoundImage } from '../../common/components/ui/RoundComponents';

const Nav = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	height: 64px;
	align-items: center;
	border-bottom: 1px solid var(--neutral-40p);
	background: var(--primary-light);
	z-index: 1000;
`;

const NavEnterpriseTitleContainer = styled.div`
	width: 100%;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 2.5;
	padding: 0 1.25rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const NavEnterpriseTitle = styled.h5`
	font-size: 20px;
	padding: 0;
	margin: 0 0 0 27px;
	align-self: center;
	max-width: 258px;
	width: 215px;

	@media (max-width: 1366px) {
		width: 190px;
		margin: 0 0 0 0.5rem;
		font-size: 18px;
	}

	@media (max-width: 1024px) {
		width: 155px;
		margin: 0 0 0 0.5rem;
		font-size: 16px;
	}
`;

const NavVerticalSeparator = styled.div`
	height: calc(100% - 20px);
	width: 1px;
	margin-right: 60px;
	background: var(--neutral-20p);
	opacity: 0.6;
`;

const NavLogo = styled.img`
	height: 28px;
	padding: 0px 25px;
	white-space: nowrap;
`;

const NavBarContainer = styled.div`
	display: flex;
	overflow: hidden;
	transition: all 0.3s ease-in;
	flex-basis: auto;
	justify-content: space-around;
	align-items: center;
	flex-grow: 3;
	flex-shrink: 1;
	height: 100%;
	width: 100%;
`;

const NavBar = styled.ul`
	width: 100%;
	padding: 0;
    margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	list-style: none;
	height: 100%;
`;

const NavItem = styled.li`
	margin-right: 40px;
	height: calc(100% - 3px); 
    padding: 0; 
    align-items: center; 
    display: flex; 
	a {
		height: 100%;
    	display: flex;
    	align-items: center;
		padding: 0;
		text-decoration: none;
		color: black;
		font-size: 16px;
		line-height: 16px;
		font-family: InterUI-Medium
	}
	&:hover > a {
		color: var(--neutral-30p);
	}
	
	@media(max-width:1360px) {
		margin-right: 22px;
		a {
			font-size: 16px;
		}
	}

`;

const activeClassName = 'active-nav';

const NavStyledNavLink = styled(NavLink).attrs({
	activeClassName: activeClassName,
})`
  &.${activeClassName} {
	border-bottom: 5px solid var(--primary-blue);
  }
`;

const NavControlsContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 3;
	padding: 0 2.5rem 0 1.5rem;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	@media(max-width:1290px) {
		padding: 0 1.5rem 0 0.5rem;
	}
`;

const NavControlsList = styled.ul`
	display: flex;
	flex-direction: row;
	list-style: none;
	padding: 0;
	align-items: baseline;
`;

const NavControlsListItem = styled(NavLink)`
	display: flex;
	flex-direction: column-reverse;
	justify-content: start;
	list-style: none;
	height: 100%;
	align-items: end;
	padding-left: 0;
	padding-right: 1.375rem;
	background: transparent;
	position: relative;

	& > img {
		width: 24px;
		height: 24px;
	}

	&:hover {
		transform: translateY(-2px) scale(1.1, 1.1);
		transition: transform 0.1s;
		transition-timing-function: ease-out;
	}
`;

const bellRingKeyfrmaes = keyframes`
	0% { -webkit-transform: rotateZ(0); }
	1% { -webkit-transform: rotateZ(30deg); }
	3% { -webkit-transform: rotateZ(-28deg); }
	5% { -webkit-transform: rotateZ(34deg); }
	7% { -webkit-transform: rotateZ(-32deg); }
	9% { -webkit-transform: rotateZ(30deg); }
	11% { -webkit-transform: rotateZ(-28deg); }
	13% { -webkit-transform: rotateZ(26deg); }
	15% { -webkit-transform: rotateZ(-24deg); }
	17% { -webkit-transform: rotateZ(22deg); }
	19% { -webkit-transform: rotateZ(-20deg); }
	21% { -webkit-transform: rotateZ(18deg); }
	23% { -webkit-transform: rotateZ(-16deg); }
	25% { -webkit-transform: rotateZ(14deg); }
	27% { -webkit-transform: rotateZ(-12deg); }
	29% { -webkit-transform: rotateZ(10deg); }
	31% { -webkit-transform: rotateZ(-8deg); }
	33% { -webkit-transform: rotateZ(6deg); }
	35% { -webkit-transform: rotateZ(-4deg); }
	37% { -webkit-transform: rotateZ(2deg); }
	39% { -webkit-transform: rotateZ(-1deg); }
	41% { -webkit-transform: rotateZ(1deg); }
	43% { -webkit-transform: rotateZ(0); }
	100% { -webkit-transform: rotateZ(0); }
`;

const AnimatedImg = styled.img`
	&.animate {
		animation: ${bellRingKeyfrmaes} 4s .2s ease-in-out infinite;
		transform-origin: 50% 4px
	}
`;


const NavControlItem = (props) => {
	return (
		<NavControlsListItem to={props.to} onClick={props.onClick}>
			<AnimatedImg src={props.icon} className={props.animate ? 'animate' : ''} alt={props.alt} />
		</NavControlsListItem>
	);
}

const NavControlButton = styled.img`
	width: 24px;
    height: 24px;
	padding-right: 1.375rem;
	cursor: pointer;
	&:hover {
		transform: translateY(-2px) scale(1.1, 1.1);
		transition: transform 0.1s;
		transition-timing-function: ease-out;
	}
`;

const NavMobileTop = styled.div`
	height: 52px; 
	box-shadow: 0px 1px var(--neutral-30p);
	width: 100%; 
	display: flex;
	flex-direction: row; 
	justify-content: space-between; 
	z-index: 1000;
	align-items: center;
`;

const NavMobileBottom = styled.div`
	margin-top: ${props => {return props.clicked ? "50px" : "none" }};
	z-index: 999;
	overflow: auto;
	padding-bottom:30px;
	background:white;
`;

const NavMobileWrapper = styled.div`
	position: fixed;
	overflow: auto;
	min-width: 100%;
	max-height: 100%;
	min-height: 52px;
	box-shadow: 0px 1px var(--neutral-30p);
	height: ${props => {return props.clicked ? "100%" : "auto" }};
	z-index: 1001;
`;

const NavButtonsCombine = styled.div`
	display: flex;
	z-index: 1000; 
	align-items: center;
`;

const Container = styled.button`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	background: transparent;
	border: none;
	color: var(--neutral-60p);
	padding: 0;
	& > * {
		margin: 0.25rem;
	}
	&:focus {
		outline: none;
	}
	&:hover {
		color: var(--neutral-50p);
	}
	&:active {
		color: var(--neutral-50p);
	}
	&:active ${RoundNameLogo}, ${RoundImage} {
		background: var(--blue-shade-dark);
	}
`;

const NavProfileButton = (props) => {
	return (
		<Container onClick={props.onClick}>
			<RoundProfileImage
				width={40}
				height={40}
				name={fullName(props.user)}
				imgUrl={imagePath(_.get(props, ['user', 'avatar']))} />
			<FontAwesomeIcon icon={faChevronDown} style={{color: 'black'}}/>
		</Container>
	);
}

export default {
	Nav,
	NavEnterpriseTitleContainer,
	NavEnterpriseTitle,
	NavVerticalSeparator,
	NavLogo,
	NavBar,
	NavBarContainer,
	NavControlsContainer,
	NavItem,
	NavStyledNavLink,
	NavControlsList,
	NavControlsListItem,
	NavControlItem,
	NavControlButton,
	NavProfileButton,
	NavMobileTop,
	NavMobileBottom,
	NavButtonsCombine,
	NavMobileWrapper,
	AnimatedImg
};