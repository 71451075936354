import * as actionTypes from '../actionTypes';
import profileApi from '../../../api/profile-api';

const profileLoading = () => {
	return {
		type: actionTypes.PROFILE_LOADING
	}
}

const profileLoadingSuccess = (profile) => {
	return {
		type: actionTypes.PROFILE_LOADING_SUCCESS,
		profile: profile
	}
}

const profileLoadingFailed = (error) => {
	return {
		type: actionTypes.PROFILE_LOADING_FAILED,
		error: error
	}
}

export const loadProfile = (requestId) => {
	return async dispatch => {
		dispatch(profileLoading());
		const { status, data, error } = await profileApi.fetchUserProfile(requestId);
		if (status === 200) {
			return dispatch(profileLoadingSuccess(data));
		}
		else {
			return dispatch(profileLoadingFailed(error))
		}
	}
}

export const cleanProfile = () => {
	return {
		type: actionTypes.PROFILE_CLEAN
	}
}

const profileUpdateSuccess = (profile) => {
	return {
		type: actionTypes.PROFILE_UPDATE_SUCCESS,
		profile: profile
	}
}

const profileUpdateFailed = (error) => {
	return {
		type: actionTypes.PROFILE_UPDATE_FAILED,
		error: error
	}
}

export const updateProfile = (profile, requestId) => {
	return async dispatch => {
		dispatch(profileLoading());
		const { status, data, error } = await profileApi.updateUserProfile(profile, requestId);
		if (status === 200 && data.ok === 1) {
			return dispatch(profileUpdateSuccess(profile));
		}
		else {
			return dispatch(profileUpdateFailed(error))
		}
	}
}

const updateProfileAvatarSuccess = (avatar) => {
	return {
		type: actionTypes.PROFILE_AVATAR_UPDATE_SUCCESS,
		avatar: avatar
	}
}

const updateProfileAvatarFailed = (error) => {
	return {
		type: actionTypes.PROFILE_AVATAR_UPDATE_SUCCESS,
		error: error
	}
}


export const updateUserAvatar = (avatar, requestId) => {
	return async dispatch => {
		dispatch(profileLoading());
		const { status, data, error } = await profileApi.updateUserAvatar({ avatar: avatar }, requestId);
		if (status === 200 && data.ok === 1) {
			return dispatch(updateProfileAvatarSuccess(avatar));
		}
		else {
			return dispatch(updateProfileAvatarFailed(error))
		}
	}
}